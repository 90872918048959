
const documentMethods = {
    splitExpDate (expDate, dateObj) {
        const {0: year, 1: month, 2: day } = expDate.split("-");
        dateObj.day = day
        dateObj.month = month
        dateObj.year = year
    }
}

export default documentMethods;