
  import { defineComponent, onMounted, ref } from "vue";
  import { useRouter } from "vue-router";
  import {IconWarningTriangle, IconArrowRight} from "@/components";
  import { noActiveEarningAccounts } from "../../modules/earnings/Helper";
  import { defaultLocationPaymentsFailed, getBranchStatus, getOneStatus, isAllPaymentMethodsFailed } from "@/modules/funnelStatus/Helpers";
import { proxyApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import { state } from "@/harlem/stores";
import { FunnelStatus } from "@/util/FunnelStatus";

  export default defineComponent({
      name: "NoActivePayments",
      components: { IconWarningTriangle, IconArrowRight },
      setup() {
        const router = useRouter();
        const isManageEarningsPage = router.currentRoute.value.name == "manage-earnings"
        const isValidDriverWithNoActiveAccounts:any = ref(false)
        const currentCandidateStatus:any = ref([])
        const navigateUser = () => {
          const currentRoute = router.currentRoute.value.name
          if (currentRoute == "earnings") {
            router.push('manage-earnings')
          } else if (currentRoute == "dashboard") {
            router.push('earnings')
          }
        }

        const isIncorrectPage = () => {
            const isManageEarningsPage = router.currentRoute.value.name == "manage-earnings"
            const isCandidateDashboardPage = router.currentRoute.value.name == "candidateDashboard"
            const isIncorrectPage = (isManageEarningsPage || isCandidateDashboardPage)
            return isIncorrectPage
        }

        const validDriverWithNoActiveAccounts = async () => {
          let funnelProgress = await proxyApi.post('/getFunnelProgress', encodeWithParam({ candidateID: state.user.locationPartnerId }))
          currentCandidateStatus.value = funnelProgress.data.result[0].candidateStatus
          const isWithdrawnStatus = currentCandidateStatus.value == FunnelStatus.WITHDRAWN;
          const noActiveAccounts = await noActiveEarningAccounts()
          const branchStatus: any = await getBranchStatus()
          const oneStatus = await getOneStatus()
          const branchPending =  ['CREATED', 'REVIEW'].includes(branchStatus)
          const branchUnclaimed =  branchStatus == 'UNCLAIMED'
          const onePending =  ['SUBMITTED', 'PENDING'].includes(oneStatus)
          const defaultLocationAllPaymentsFailed = await defaultLocationPaymentsFailed()
          if (await isAllPaymentMethodsFailed() == false) {
            if (!(defaultLocationAllPaymentsFailed)) {
              if (
                noActiveAccounts && 
                !(branchUnclaimed || branchPending || onePending) &&
                isWithdrawnStatus == false
                ) {
                return isValidDriverWithNoActiveAccounts.value = true
              }
            }
          }
        }
        
        onMounted(async () => {
          await validDriverWithNoActiveAccounts()
        })

          return { isValidDriverWithNoActiveAccounts, navigateUser, isManageEarningsPage, isIncorrectPage };
      },
  });
  