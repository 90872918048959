
import { defineComponent } from "vue";
import { IconWalmart } from "@/components";
export default defineComponent({
  name: "DirectDepositLoader",
  components: {
    IconWalmart
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
})
