import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "field-error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_warning_octagon = _resolveComponent("icon-warning-octagon")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createBlock(_component_ErrorMessage, { name: _ctx.name }, {
    default: _withCtx(({ message }) => [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_icon_warning_octagon),
        _createTextVNode(" " + _toDisplayString(message), 1)
      ])
    ]),
    _: 1
  }, 8, ["name"]))
}