import { createStore } from "@harlem/core";

const STATE = {
  branchResponse: "",
  activeEarningAccounts: [],
  isPaymentAgreementComplete: false
};
const restoreState = (): any => {
  const userState = sessionStorage.getItem("ddi:payment");

  if (!userState) {
    return STATE;
  } else {
    return {
      ...JSON.parse(userState),
    };
  }
};
export const { state, getter, mutation, on } = createStore(
  "payment",
  restoreState()
);
