<template>
  <!-- <img class="loader-img"
    src="data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='9.625' width='2.75' height='8.25' rx='1.375' fill='%2316789E'/%3E%3Crect opacity='0.5' x='9.625' y='13.75' width='2.75' height='8.25' rx='1.375' fill='%2316789E'/%3E%3Crect opacity='0.8' x='22' y='9.625' width='2.75' height='8.25' rx='1.375' transform='rotate(90 22 9.625)' fill='%2316789E'/%3E%3Crect opacity='0.25' x='8.25' y='9.625' width='2.75' height='8.25' rx='1.375' transform='rotate(90 8.25 9.625)' fill='%2316789E'/%3E%3Crect opacity='0.9' x='17.8052' y='2.24982' width='2.75' height='8.25' rx='1.375' transform='rotate(45 17.8052 2.24982)' fill='%2316789E'/%3E%3Crect opacity='0.35' x='8.08347' y='11.9722' width='2.75' height='8.25' rx='1.375' transform='rotate(45 8.08347 11.9722)' fill='%2316789E'/%3E%3Crect opacity='0.65' x='19.7509' y='17.8058' width='2.75' height='8.25' rx='1.375' transform='rotate(135 19.7509 17.8058)' fill='%2316789E'/%3E%3Crect opacity='0.15' x='10.0279' y='8.0835' width='2.75' height='8.25' rx='1.375' transform='rotate(135 10.0279 8.0835)' fill='%2316789E'/%3E%3C/svg%3E"
  /> -->
  <svg
    class="loading"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 1C12.5523 1 13 1.44772 13 2V6C13 6.55228 12.5523 7 12 7C11.4477 7 11 6.55228 11 6V2C11 1.44772 11.4477 1 12 1Z"
      fill="#16789E"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 17C12.5523 17 13 17.4477 13 18V22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22V18C11 17.4477 11.4477 17 12 17Z"
      fill="#16789E"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.22289 4.22289C4.61342 3.83236 5.24658 3.83236 5.63711 4.22289L8.46711 7.05289C8.85763 7.44341 8.85763 8.07658 8.46711 8.4671C8.07658 8.85762 7.44342 8.85762 7.05289 8.4671L4.22289 5.6371C3.83237 5.24658 3.83237 4.61341 4.22289 4.22289Z"
      fill="#16789E"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.5329 15.5329C15.9234 15.1424 16.5566 15.1424 16.9471 15.5329L19.7771 18.3629C20.1676 18.7534 20.1676 19.3866 19.7771 19.7771C19.3866 20.1676 18.7534 20.1676 18.3629 19.7771L15.5329 16.9471C15.1424 16.5566 15.1424 15.9234 15.5329 15.5329Z"
      fill="#16789E"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1 12C1 11.4477 1.44772 11 2 11H6C6.55228 11 7 11.4477 7 12C7 12.5523 6.55228 13 6 13H2C1.44772 13 1 12.5523 1 12Z"
      fill="#16789E"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17 12C17 11.4477 17.4477 11 18 11H22C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13H18C17.4477 13 17 12.5523 17 12Z"
      fill="#16789E"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.46711 15.5329C8.85763 15.9234 8.85763 16.5566 8.46711 16.9471L5.63711 19.7771C5.24658 20.1676 4.61342 20.1676 4.22289 19.7771C3.83237 19.3866 3.83237 18.7534 4.22289 18.3629L7.05289 15.5329C7.44342 15.1424 8.07658 15.1424 8.46711 15.5329Z"
      fill="#16789E"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.7771 4.22289C20.1676 4.61341 20.1676 5.24658 19.7771 5.6371L16.9471 8.4671C16.5566 8.85762 15.9234 8.85762 15.5329 8.4671C15.1424 8.07658 15.1424 7.44341 15.5329 7.05289L18.3629 4.22289C18.7534 3.83236 19.3866 3.83236 19.7771 4.22289Z"
      fill="#16789E"
    />
  </svg>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Loader",
});
</script>
