<template>
  <svg v-svg="'icon-x'" size="24 24" />
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "IconX",
});
</script>
