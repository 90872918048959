
import { defineComponent, ref } from "vue";
import { BaseModal } from "@/components";
import { onMounted } from "vue";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import { proxyApi } from "@/http/http.config";

export default defineComponent({
  name: "IneligibleModal",
  components: { BaseModal, },
  emits: ['close'],
  
  // setup() {
  //   setStatus("ELIGIBILITY", "DID_NOT_QUALIFY")
  //   onMounted( async () => {console.log("trying")
  //   proxyApi.post('/addApplicationStatus', encodeWithParam(
  //     {
  //       stepName: 'ELIGIBILITY',
  //        stepStatus: 'DID_NOT_QUALIFY',
  //     }
  //       ))
	// 	})   
  // }
});
