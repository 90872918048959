
import { defineComponent } from "vue";
import { Field } from "vee-validate";
import { FieldError } from "@/components";

export default defineComponent({
  name: "StateAutoPopulate",
  components: { Field, FieldError },
 data() { 
   return {
     selectedState: ""
   }
 },
  props: {
    id: {
      type: String,
      default: "state",
    },
    name: {
      type: String,
      default: "state",
    },
    label: {
      type: String,
      required: false,
      default: "State",
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    validateOnMount: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasError: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onChangeState(event) {
      this.selectedState = event;
    }
  }
});
