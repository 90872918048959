import { createStore } from "@harlem/core";

const STATE = {
  clientId: "",
  locationId: "",
  locationName: "",
  locationLogo: "",
  deliveryMap: "",
  onBoardingSpecialist: "",
  personalDetails: {} as { [key: string]: any },
  IRSForm: {} as { [key: string]: any },
  alertTimes: [],
};

const restoreState = (): any => {
  const signupState = sessionStorage.getItem("ddi:signup");

  if (!signupState) {
    return STATE;
  } else {
    return {
      ...JSON.parse(signupState),
    };
  }
};

export const { state, getter, mutation, on } = createStore(
  "signup",
  restoreState()
);
