
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseModal",
  emits: ["close"],
  props: {
    id: {
      type: String,
      required: true,
      default: "",
    },
  },
});
