
import { defineComponent } from "vue";
import { Field } from "vee-validate";
import { FieldError } from "@/components";
export default defineComponent({
  name: "AchAccountCategory",
  emits: ['showAccountCategory', 'switch'],
  components: {
    Field,
    FieldError
  },
  props: {
    category: {
      type: String,
      required: true,
      default: ""
    },
    disabled: {
      type: Boolean,
      required: true,
      default: true
    }
  },
})
