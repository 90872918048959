import { createStore } from "@harlem/core";

const STATE = {
  userId: "",
  username: "",
  firstName: "",
  lastName: "",
  preferredName: "",
  phone: "",
  email: "",
  comm_email: "",
  signature: "",
  partnerId: "",
  locationPartnerId: "",
  locationId: "",
  locationName: "",
  ip: "",
  garnishment: false,
  isOneIntegrated: false,
  oneStatus: "",
  isActive: false,
  oneStartDate: ""
};
const restoreState = (): any => {
  const userState = sessionStorage.getItem("ddi:user");

  if (!userState) {
    return STATE;
  } else {
    return {
      ...JSON.parse(userState),
    };
  }
};
export const { state, getter, mutation, on } = createStore(
  "user",
  restoreState()
);
