import { object, string, ref } from "yup";

export default object().shape({
  category: string().required('Account category is required'),
  name: string().required('Account holder name is required'),
  type: string().required('Account type is required'),
  routing: string()
    .matches(/^[0-9]{9}$/, 'Routing numbers are 9 digits, with no special characters.')
    .required('Routing numbers are 9 digits, with no special characters.'),
  confirmRouting: string()
    .required("Routing numbers don't match.")
    .oneOf([ref('routing'), null], "Routing numbers don't match"),
  account: string()
    .matches(/^[0-9]{1,17}$/, 'Account numbers are 17 digits, with no special characters.')
    .required('Account numbers are 17 digits, with no special characters.'),
  confirmAccount: string()
    .required("Account numbers don't match.")
    .oneOf([ref('account'), null], "Account numbers don't match"),
  email: string()
    .trim()
    .required()
    .test(
      "Email",
      function (value: any) {
        if (value.length == 0) {
          return this.createError({ message: "Email is required" })
        } else if (!/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,}$/.test(value)) {
          return this.createError({ message: "Not a valid email" })
        } else return true;
      }
    )
})
