import { createStore } from "@harlem/core";

const STATE = {
  id: "",
  status: "",
  currentStep: "",
  candidateStatus: "",
  candidateStep: "",
};

const restoreState = (): any => {
  const applicationState = sessionStorage.getItem("ddi:application");

  if (!applicationState) {
    return STATE;
  } else {
    return {
      ...JSON.parse(applicationState),
    };
  }
};

export const { state, getter, mutation, on } = createStore(
  "application",
  restoreState()
);
