import * as user from "./user.mutation";
import * as payment from "./payment.mutation";
import * as application from "./application.mutation";
import * as funnel from "./funnel.mutation";
import * as signup from "./signup.mutation";
import * as mergeTags from "./mergeTags.mutation";
import * as business from './business.mutation'
export const mutation = {
  user,
  payment,
  application,
  funnel,
  signup,
  mergeTags,
  business,
};
