<template>
  <div>
    <div>
      <div>
        <div class="confirmation-details">
          <div class="section-header">
            Are following details correct?
          </div>
          <p>
            These details will be used for your background check.
            <b>Please ensure they're accurate - you can edit all the fields below except for your Full Name.</b>
          </p>
          <div class="user-details">
            <h4>Full Name</h4>
            <p> {{ fullName }}</p>
            <h4>Social insurance number (SIN)</h4>
            <div class="has-icon">
              <icon-eye-closed v-if="passwordHidden" @click="togglePassword" />
              <icon-eye-open v-else @click="togglePasswordTrue" />
            </div>
            <p>{{ sin }} </p>
            <h4>Date Of Birth</h4>
            <p>{{ dob }}</p>
            <h4>Home address</h4>
            <p>{{ address }}</p>
            <div class="user-details" v-if="businessDetailsAvailable">
              <h4>Business name</h4>
              <p>{{ businessName }}</p>
              <h4>Business identification number</h4>
              <p>{{ BIN }}</p>
              <h4>Business address</h4>
              <p>{{ Baddress }}</p>
              <h4>GST/HST number</h4>
              <p>{{ gsthst }}</p>
            </div>

            <ineligible-modal />


          </div>
          <div class="nav-buttons">
            <button id="" class="button back-button" type="button" @click="redirectTOBack">Edit</button>
            <!-- <button v-if="loading" class="button next-button" type="button">
            <pulse-loader :loading="loading" size="10px" color="#fff"></pulse-loader>
          </button> -->
            <button class="button next-button" type="button" @click="saveDetails">SUBMIT</button>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import micromodal from "micromodal";
import { proxyApi, restApi, kycApi } from "@/http/http.config";
import { decrypt, encodeWithParam, encrypt } from "@/util/DataEncodeDecode";
import { IconEyeClosed, IconEyeOpen,IneligibleModal } from "@/components";
import { useRoute, useRouter } from "vue-router";
export default {
  name: "personalInfoConfirmation",
  components: { IconEyeClosed, IconEyeOpen,IneligibleModal },
  setup() {
    let personalDetails;
    const fullName = ref("");
    const dob = ref("");
    var licenseNumber = ref(false);
    var stateName = ref(false);
    var expiryDate = ref(false);
    const address = ref("");
    const sin = ref("");
    const passwordHidden = ref(true);
    const businessName = ref("");
    const Baddress = ref("");
    const gsthst = ref("");
    const BIN = ref("");
    const businessDetailsAvailable = ref(false);


    onMounted(() => {
      personalDetails = JSON.parse(localStorage.getItem('driverDetails'));
      let address2 = personalDetails.address2 ? personalDetails.address2+',' : "";
      let Busaddress2 = personalDetails.businessaddress2 ? personalDetails.businessaddress2+',' : "";

      fullName.value = personalDetails.firstName + ' ' + personalDetails.lastName;
      dob.value = personalDetails.dateOfBirth;
      address.value = personalDetails.address1 + ', ' + address2 + ' ' + personalDetails.city +', '+ personalDetails.state+ ', ' + personalDetails.zip;

      //  sin.value = personalDetails.ssn;
      sin.value = "*******";
      console.log(localStorage.getItem('isBusiness'), "isBusiness Flag")
      if (localStorage.getItem('isBusiness') == 'false' || localStorage.getItem('isBusiness') == null) {
        businessDetailsAvailable.value = false;
      } else {
        businessDetailsAvailable.value = true;
        businessName.value = personalDetails.businessName;
        BIN.value = personalDetails.ein;
        Baddress.value = personalDetails.businessaddress1 +', ' + Busaddress2 +personalDetails.businesscity + ', '+personalDetails.business_state+', '+personalDetails.businesszip;
        gsthst.value = personalDetails.taxNo;
      }
    })


    const togglePassword = () => (
      passwordHidden.value = false,
      sin.value = '******'+personalDetails.ssn.substr(personalDetails.ssn.length - 3)
    );

    const togglePasswordTrue = () => (
      passwordHidden.value = true,
      sin.value = "*******"
    )


    const router = useRouter();
    const saveDetails = async () => { 
      const partnerSIN = await encrypt(personalDetails.ssn.split("-").join(""));

      let einValue = personalDetails.ein
                  ? personalDetails.ein?.toString()?.includes("-")
                    ? personalDetails.ein
                    : (personalDetails.ein.toString().slice(0, 2) +
                      "-" +
                      personalDetails.ein.toString().slice(2))
                  : "";


        let obj = {
          isBusiness:businessDetailsAvailable.value,
          driverKYC: { 
            sin: personalDetails.ssn.split("-").join(""),
            encrypted_sin: partnerSIN,
            dlCountry: personalDetails.country,
            dlState: personalDetails.licence_state,
            dlNumber: personalDetails.driver_license,
            dlExpiry: personalDetails.license_expiry_date,
          },
          partnerPersonalDetail: { 
            dob: personalDetails.dateOfBirth,
            firstName: personalDetails.firstName,
            lastName: personalDetails.lastName
          },
          address: { 
            address1: personalDetails.address1,
            address2: personalDetails.address2,
            address3: personalDetails.address3, // personalDetails.address3,
            city: personalDetails.city,
            state: personalDetails.state,
            postalCode: personalDetails.zip,
            country: "CA"
          },
         businessPayload: {
                businessName: personalDetails.businessName,
                ein: einValue,
                gsthstNumber: personalDetails.taxNo,
                businessaddress: {
                  address1: personalDetails.businessaddress1,
                  address2: personalDetails.businessaddress2,
                  address3: null, // personalDetails.address3,
                  city: personalDetails.businesscity,
                  state: personalDetails.business_state,
                  postalCode: personalDetails.businesszip,
                  country: "CA",
                  county: personalDetails.businesscounty
                }
              }
        };

        console.log(obj)
        proxyApi.post("/updateDriversDetails", encodeWithParam(obj))
        .then(async ({ data }) => {
          console.log(data)
          if(data.success){
            proxyApi.get('/driverKyc').then(async (data) => { 
                router.push({ 
                  name: 'kycProcessing', 
                })
            })
          }
          else if(!data.success && (data.result == "SIN" || data.result == "DL")){
            // router.push({ 
            //       name: 'ReviewEdit', 
            //     })
            micromodal.show('modal-ineligible')
          }
          // else if(!data.success && data.result == "DL"){
            // micromodal.show('modal-ineligible')
          // }
          else{
            router.push({ 
                  name: 'kycFail', 
                })
          }
          
        }) 

    };

    const redirectTOBack = async () => {
      // console.log(personalDetails, "in edit details")
      // let driversDetails = JSON.parse(props.driversDetails);
      return router.push({
        name: "fullProfileDetails",
        params: {
          driversDetails: JSON.stringify(personalDetails)
        }
      });
    };

    return {
      saveDetails,
      fullName,
      licenseNumber,
      address,
      dob,
      sin,
      stateName,
      expiryDate,
      redirectTOBack,
      togglePassword,
      togglePasswordTrue,
      passwordHidden,
      businessName,
      Baddress,
      gsthst,
      BIN,
      businessDetailsAvailable
    }
  },
  methods: {


  },
}
</script>
<style>
.confirmation-details {
  padding: 10px;
  background: #fbfbfb;
}

.confirmation-details>p {
  margin-right: 10px;
  font-size: 18px;
  line-height: 150%;
  color: #FE3945;
}

.section-header {
  font-weight: 700;
  font-size: 24px;
  line-height: 1em;
  color: #3B4459;
  margin-bottom: 1em;
  margin-left: 6px;
}

.user-details {
  margin-bottom: 40px;
}

.user-details>h4 {
  margin-top: 40px;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: #8D95AA;
}

.user-details>p {
  margin-top: -14px;
  font-size: 16px;
  line-height: 24px;
  color: #3B4459;
}

.back-button {
  background-color: #EBEBEB;
  color: var(--dark-blue);
  margin: 8px;
}

.next-button {
  background: white;
  border: 2px solid var(--walmart-secondary);
  color: var(--walmart-secondary);
  font-weight: normal;
  cursor: pointer;
  display: block;
  border: none;
  /* -webkit-appearance: none; */
  background: var(--walmart-blue);
  /* border: 2px solid transparent; */
  border: 1px solid #bfc2cd;
  color: var(--white);
  border-radius: 44px;
  padding: 13px 18px;
  text-decoration: none;
  text-align: center;
  width: 100%;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.has-icon {
  float: right;
  margin-top: -20px;
}</style>