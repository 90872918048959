<template>
  <div id="ddi-payment-policy" class="modal" aria-hidden="true">
    <div class="modal-window_overlay policy" tabindex="-1">
      <div class="modal-window_container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby">
        <header class="modal-header">
          <div class="close-modal close-modal-header" aria-label="Close modal">
          </div>
        </header>
        <div class="modal-content">
          <slot>
            <h1>Digital Wallet</h1>
            <br />
            <p>Earnings from Walmart via Branch</p>
            <img class="ddi-branch-logo" src="@/assets/walmart+branch.png" alt="DDI and Branch" />
            <br />
            <p class="text-alignment">
              Your delivery earnings from Walmart will be distributed via Branch, a secure digital wallet backed by an
              FDIC-insured checking account that sends your earnings right to your phone. With your Branch account and
              debit card, you'll not only receive earnings, but be able to transfer funds and track your earnings and
              purchase history right through the Branch App. Plus you'll have the option to pay bills or spend via Apple
              and Google Pay and the Branch debit card.
            </p>
            <br />
            <p class="text-alignment">
              A custom link to set up your personal Branch account will be sent to you. Do not download the app prior to
              receiving your custom link. You must have a custom link in order to download the app with your unique
              personal information. Once you complete the Branch account opening process and agree to applicable Branch
              terms, you’re all set.
            </p>
            <br />
            <p class="text-alignment">
              By clicking below, you agree to have Walmart share your information with Branch to help facilitate this
              payments process.
            </p>
            <br />
            <p class="text-alignment">
              Terms link = <a target="_blank" href="https://www.branchapp.com/terms">https://www.branchapp.com/terms</a>
            </p>
            <div class="button-group button-group-right space-top module full">
              <button @click="agreeContinue" :class="['button']">Accept</button>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "../../assets/css/components/modals.scss"
export default {
  name: "paymentModal",
  emits: ["accept"],
  methods: {
    agreeContinue() {
      this.$emit("accept");
    },
  },
};
</script>

<style scoped>
.ddi-branch-logo {
    padding-top: 20px;
    padding-bottom: 20px;
    /* max-width: 80%; */
    display: block;
    margin-left: auto;
    margin-right: auto;
}
</style>