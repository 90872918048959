<template>
  <div id="duplicate-phone" class="modal duplicateuser" aria-hidden="true">
    <div
      class="modal-overlay"
      tabindex="-1"
    >
      <div
        class="modal-container custom-modal-container"
        role="dialog"
        aria-modal="true"
        aria-labelledby="aria-labelledby"
      >
        <header class="modal-header">
            <div
              class="close-modal close-modal-header"
              aria-label="Close modal"
              data-micromodal-close="data-micromodal-close"
            >
              <icon-x />
            </div>
        </header>
        <div class="modal-content">
          <slot>
            <div class="err-header">Phone Already Exists</div>
            <br />
            <img
              class="branch-err-logo"
              src="@/assets/alert-octagon_l.png"
              alt="DDI and Branch"
            />
            <br />
            <p class="text-alignment">
                It appears your phone already exists.
                Or you can recover your credentials if you have forgotten them.
            </p>
            <div class="button-group button-group-right space-top module full"> 
                <button @click="$router.push({name: 'login'})" :class="['button']">GOTO LOGIN</button>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { IconX } from "@/components";
export default {
  name: "duplicatePhone",
  components: { IconX },
};
</script>
<style>
.duplicateuser .modal-overlay .modal-container{
    position: fixed;
    top: auto;
    right: auto;
    left: auto;
    bottom: 0;
    width: 95%;
}

.branch-err-logo {
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 30%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.err-header {
    font-size: 48px;
    font-size: 2.2857142857rem;
}

</style>