import { resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "status" }
const _hoisted_2 = {
  key: 0,
  class: "icon-success",
  size: "28 28"
}
const _hoisted_3 = {
  key: 1,
  class: "icon",
  size: "28 28"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_svg = _resolveDirective("svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.checked)
      ? _withDirectives((_openBlock(), _createElementBlock("svg", _hoisted_2, null, 512)), [
          [_directive_svg, 'icon-check']
        ])
      : _withDirectives((_openBlock(), _createElementBlock("svg", _hoisted_3, null, 512)), [
          [_directive_svg, 'icon-warning']
        ])
  ]))
}