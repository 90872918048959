import { createStore } from "@harlem/core";
import { FunnelStep } from "@/harlem/models";

interface State {
  id?: string;
  steps?: FunnelStep[];
}

const STATE: State = {
  id: "",
  steps: [],
};
const restoreState = (): any => {
  const funnelState = sessionStorage.getItem("ddi:funnel");

  if (!funnelState) {
    return STATE;
  } else {
    return {
      ...JSON.parse(funnelState),
    };
  }
};

export const { state, getter, mutation, on } = createStore(
  "funnel",
  restoreState()
);
