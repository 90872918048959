
import { defineComponent } from 'vue';
import { proxyApi } from "@/http/http.config";
import { gTagEvent } from '@/util/GoogleAnalytics';
import { state } from '@/harlem/stores';

export default defineComponent({
  name: 'TaxConsentFaq',
  props: {
    close: { type: Function }
  },
  data() {
    return {
      allowTaxesDownload: false,
      faq: [],
    }
  },
  mounted() {
    proxyApi.get('/getTaxConfig').then(({data}) => {
      this.allowTaxesDownload = JSON.parse(data.result[0].value).driverTaxes
    })
    proxyApi.get('/getTaxFaq').then(async (data) => {
      this.faq = data.data.result
    })
    gTagEvent("View FAQS Modal", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
  },  
})
