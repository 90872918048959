import { state } from "@/harlem/stores";
import { event } from "vue-gtag";

export const gTagEvent = (name, category, value, isTimed = false, time = "0") => {
    const eventBody = {
        event_category: `${category}`,
        event_value: `[${value}]`,
        event_url: window.location.pathname,
    }

    if (isTimed) {
        eventBody["time_on_page"] = time
    }
    
    event(`${name}`, eventBody);
};
