
import { FileViewer } from "@/components";
import IconInfo from "@/components/icons/IconInfo.vue";
// import SignupHeader from "@/modules/signup/components/SignupHeader.vue";
import { computed, defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { state } from "@/harlem/stores";
import { returnSteps } from "@/util/Steps";
import { mutation } from "@/harlem/mutation";

export default defineComponent({
  components: { IconInfo, FileViewer },
  name: "SignupLayout",
  setup: function () {
    const route = useRoute();
    const noSignupNav = computed(() => route.meta.noSignupNav);
    const noSignupFooter = computed(() => route.meta.noSignupFooter);
    const validRecruitsRoutes = ["/signup", "/eligibility", "/complete"];
    watch(
      () => state.signup.clientId,
      () => {
        if (
          state.signup.clientId === "b8b95baf-e76b-49b6-b3a8-2ca0706765b8" &&
          validRecruitsRoutes.includes(route.path)
        ) {
          let recruitcsScript = document.createElement("script");
          recruitcsScript.setAttribute(
            "src",
            "//jsv3.recruitics.com/dce806a7-fd73-11e8-b13f-db152c0fbd76.js"
          );
          document.head.appendChild(recruitcsScript);
        }
      }
    );
    return {
      state,
      noSignupNav,
      noSignupFooter,
    };
  },
});
