
import { defineComponent } from "vue";
export default defineComponent({
  name: "AchNumberText",
  props: {
    type: {
      type: String,
      required: true,
      default: ""
    }
  }
})
