
import { defineComponent, onMounted } from "vue";
import { BaseModal } from "@/components";
import { state } from "@/harlem/stores";
import { proxyApi, restApi } from "@/http/http.config";
import CommonService  from "@/services/common.service";

export default defineComponent({
  name: "CongratsModal",
  components: { BaseModal },
  emits: ["continue"],
  setup() {
    onMounted(() => {
      CommonService.triggerRecruticsScript()
    });
  },
});
