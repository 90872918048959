
import { defineComponent } from "vue";
import IconArrowLeft from "@/components/icons/IconArrowLeft.vue";

export default defineComponent({
  name: "PageActions",
  components: { IconArrowLeft },
  emits: ["arrow-clicked"],
  props: {
    text: {
      type: String,
      required: false,
      default: "",
    },
    goto: {
      type: String,
      required: false,
      default: "",
    },
  },
});
