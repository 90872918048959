
import { defineComponent } from "vue";
import { IconInfo } from "@/components";
import IconWarningOctagon from "@/components/icons/IconWarningOctagon.vue";

export default defineComponent({
  name: "FormError",
  components: { IconWarningOctagon },
  props: {
    meta: {
      type: Object,
      required: true,
    },
  },
});
