import { UserSetters, UserState } from "@/store/states/user.state";

export const userState = {} as UserState;
export const userSetters = {
  setUserId: (userId) => {
    userState.userId = userId;
  },
  setUsername: (username) => {
    userState.username = username;
  },
  setPartnerId: (partnerId) => {
    userState.partnerId = partnerId;
  },
  setName: (name) => {
    userState.name = name;
  },
  setPreferredName: (name) => {
    userState.preferredName = name;
  },
  setPhone: (phone) => {
    userState.phone = phone;
  },
  setSignature: (signature) => {
    userState.signature = signature;
  },
  setEmail: (email) => {
    userState.email = email;
  },
} as UserSetters;
