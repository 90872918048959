
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "SaveCancel",
  props: {
    saveLabel: {
      type: String,
      required: false,
      default: "Save",
    },
    cancelLabel: {
      type: String,
      required: false,
      default: "Cancel",
    },
    cancelRoute: {
      type: String,
      required: false,
      default: "",
    },
    saveEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ["on-save"],
  setup(props, { emit }) {
    const router = useRouter();

    const save = () => {
      emit("on-save");
    };

    const cancel = () => {
      if (props.cancelRoute) {
        router.push(props.cancelRoute);
      } else {
        router.back();
      }
    };

    return { save, cancel };
  },
});
