<template>
 <div id="one-not-eligible" class="modal" aria-hidden="true">
    <div class="modal-window_overlay policy" tabindex="-1">
      <div class="one-window_container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby">
         <div class="modal-content">
          <svg v-svg="'icon-warning'" size="70 70" />
          <div class="closeBut" @click="close">
         <svg v-svg="'icon-x'" size="28 28" />
          </div>
              <h2 style="color:#3171FF">Thank you for your interest</h2>
              <p style="font-size: 18px;"> 
            Unfortunately this new feature hasn't been launched in your zone yet. 
            You will be notified when it's ready
            </p>
          <button class="one-button" @click="close"><b>Got it</b></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "../../assets/css/components/modals.scss"
import micromodal from "micromodal";
import { useRouter } from "vue-router";
export default {
  name: "OneNotEligible",
  setup(){
     const router = useRouter();
     const close= () =>{
      micromodal.close('one-not-eligible')
      router.push({name: "dashboard"});
    }
    return{
      close
    }
  }
};
</script>

<style scoped>

.one-window_container {
    background-color: #fff;
    padding: 20px 20px 50px 20px;
    width: 100%;
    max-width: 700px;
    max-height: 100%;
    /* CHANGED */
    border-radius: 10px 10px 0px 0px;
    overflow-y: auto;
    box-sizing: border-box;
}
.closeBut{
  float: right;
  color: black;
}
.xbutton{
  background-color: none;
  cursor: pointer;
  font-size: 16px;
 color: #3171FF;
 border: none;
}

  .one-button {
    cursor: pointer;
    display: block;
    border: none;
    -webkit-appearance: none;
    background: var(--walmart-blue);
    border: none;
    color: #ffffff;
    border-radius: 44px;
    padding: 13px 18px;
    text-decoration: none;
    text-align: center;
    width: 100%;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
</style>
