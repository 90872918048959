import { resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "icon" }
const _hoisted_2 = { size: "32 32" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_svg = _resolveDirective("svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("svg", _hoisted_2, null, 512)), [
      [_directive_svg, 'icon-upload']
    ])
  ]))
}