const convertHextToArrayBuffer = (string)=>{
    let bytes = [];
    string.replace(/../g, function (pair) {
      bytes.push(parseInt(pair, 16));
    });
    return new Uint8Array(bytes).buffer;
}

const convertArrayBufferToBase64 = (arrayBuffer)=> {
  let arr = new Uint8Array(arrayBuffer)
  return window.btoa(
    arr.reduce((data, byte) => data + String.fromCharCode(byte), '')
    );
  }

export const convertBufferToContent = (hexaDecimal,contentType)=> {
    let imageBuffer = convertHextToArrayBuffer(hexaDecimal);
    let base64String = convertArrayBufferToBase64(imageBuffer)
    return `data:${contentType};base64,${base64String}`
}
