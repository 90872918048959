import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_svg = _resolveDirective("svg")!

  return _withDirectives((_openBlock(), _createElementBlock("svg", {
    size: "10.5 7.8",
    class: _normalizeClass($props.classes)
  }, null, 2)), [
    [_directive_svg, 'icon-check']
  ])
}