
import { defineComponent } from "vue";
import { ErrorMessage } from "vee-validate";
import { IconWarningOctagon } from "@/components";

export default defineComponent({
  name: "FieldError",
  components: { IconWarningOctagon, ErrorMessage },
  props: {
    name: {
      type: String,
      required: true,
      default: "",
    },
  },
});
