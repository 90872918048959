
import { defineComponent, onMounted, onUnmounted, reactive, ref } from "vue";
import { Form, Field } from 'vee-validate';
import { state } from "@/harlem/stores";
import { maska } from "maska";
import { useRoute, useRouter } from "vue-router";
import "../../../assets/css/components/formInput.scss";
import { encode, decode, encodeWithParam } from "@/util/DataEncodeDecode";
import { FieldError } from "@/components";
import TextFieldValidated from "@/components/forms/TextFieldValidated.vue";
import * as yup from "yup";
import micromodal from "micromodal";
import { signup, proxyApi, restApi } from "@/http/http.config";
import { mutation } from "@/harlem/mutation";
import authenticationService from "@/services/authentication.service";
import { gTagEvent } from "@/util/GoogleAnalytics";
import {
  IconEyeClosed,
  IconEyeOpen,
  LearnMore,
  // WalmartPrivacyPolicy,
  // WalmartTaxConsentPolicy,
  paymentModal,
  duplicateUser
} from "@/components";
import Base64 from "base-64";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { DateTime } from "luxon";
import { setStatus } from "@/services/stage.service";
import checklistMethods from "../components/Checklist/Helpers/Index";
import SelectorValidated from "@/components/forms/SelectorValidated.vue"
import { validations } from "@/util/Validations";
import { EligibilityQuestion, Option } from "./Verification"
import CommonService from "@/services/common.service";
import { usernameEmail } from "@/modules/login/constants";
import { FunnelStatus } from "@/util/FunnelStatus";

export default defineComponent({
  name: "CreateAccount",
  components: {
    Form,
    Field,
    TextFieldValidated,
    IconEyeOpen,
    // WalmartPrivacyPolicy,
    // WalmartTaxConsentPolicy,
    IconEyeClosed,
    // paymentModal,
    FieldError,
    duplicateUser,
    SelectorValidated,
    PulseLoader
  },
  directives: { maska },

  data() {
    return {
      state, 
      errorMsg: { error: false, message: "" },
    }
  },

  methods:{
    isMobile() {
      return checklistMethods.isMobile();
    },
  },

  setup() {
    const btnDisabled = ref(true);
     
    const formError = ref("");
    const passwordHidden = ref(true);
    const phoneEmpty = ref(false);
    const confirmPasswordHidden = ref(true);
    // const isTaxConsentEntered = ref(false);
    let taxConsentDetails = null;
    let checkEmail = "";
    const responseEmail = ref({ email: "", boolean: true });
    // const acceptWalmartPolicy = ref(false);
    const isPolicyMandatoryError = ref(false);
    const acceptBranch = ref(true);
    const responseUsername = ref({ username: "", boolean: true });
    const route = useRoute();
    const router = useRouter();
    const phoneNumber = ref("");
    const signUpTermError = ref("");
    const funnelQuestions = ref<EligibilityQuestion[]>([])
    // const pageTimeStart = ref(0)
    const loading = ref(false);

    const payload = ref({
      ans: [] as any[],
    })
    onMounted(() => {
      // let data = sessionStorage.getItem("signupData")
      // if (!data || data === '') {
      //   localStorage.clear();
      //   sessionStorage.clear();
      //   router.push({ name: "login" });
      // }

      // pageTimeStart.value = performance.now()
       
    })

    onUnmounted(() => {
      gTagEvent("Enter Create Account Page", "Create Account", true)
    })

    const initialValues = reactive({
      firstName: "",
      lastName: "",
      preferredName: "",
      referralCode: "",
      source: "",
      email: "",
      password: "",
      confirmPassword: "",
      acceptWalmartTerms: false,
      consent:false
    });

    const sourceOptions = [
      { value: 'Social Media', text: 'Social Media' },
      { value: 'Facebook', text: 'Facebook' },
      { value: 'Tik Tok', text: 'Tik Tok' },
      { value: 'Internet Search', text: 'Internet Search' },
      { value: 'YouTube', text: 'YouTube' },
      { value: 'Email', text: 'Email' },
      { value: 'Job Ad', text: 'Job Ad' },
      { value: 'Indeed', text: 'Indeed' },
      { value: 'Nextdoor', text: 'Nextdoor' },
      { value: 'Referral', text: 'Referral' },
      { value: 'DoorDash Driver', text: 'DoorDash Driver' },
      { value: 'Spark Now Driver', text: 'Driver on the Spark Driver App' },
      { value: 'Uber Driver', text: 'Uber Driver' },
      { value: 'Glass Door', text: 'Glass Door' },
      { value: 'In Store Recommendation', text: 'In Store Recommendation' },
      { value: 'Friend or Family', text: 'Friend or Family' },
      { value: 'App Store', text: 'App Store' },
      { value: 'Google Play', text: 'Google Play' },
      { value: 'Apple App Store', text: 'Apple App Store' },
      { value: 'Other', text: 'Other' },
    ]

    const isAllowedtoSignUp = ref(false);


    const getPhoneNumber = () => {
      if (sessionStorage.getItem("signupData_phone")) {
        let obj = decode(sessionStorage.getItem("signupData_phone"));
        phoneNumber.value = obj;
      }
    }

    const redirectTosignup = () => {
      router.push({ name: 'PhoneVerification',
        query: {
              locationId: route.query.locationId,
              name: route.query.name,
            }
          })
    }

     
        

    // const walmartTaxConfirmation = (data) => {
    //   micromodal.close("walmart-tax-consent-policy");
    //   taxConsentDetails = data;
    //   signupFormData["TaxConsentDetails"] = taxConsentDetails;
    //   isTaxConsentEntered.value = true;
    //   errorReset();
    // };

    // const acceptPayProcessor = (val) => {
    //   micromodal.close("ddi-payment-policy");
    //   acceptBranch.value = val;
    //   errorReset();
    // };
    const openModal = (modal: string) => {
      micromodal.show(modal);
    };
    const togglePassword = (val: number) => {
      if (val === 1) {
        passwordHidden.value = !passwordHidden.value;
      }
      else {
        confirmPasswordHidden.value = !confirmPasswordHidden.value;
      }
    };
    const isChecked = ref(false)
    const handleConsent =()=>{
      isChecked.value == !isChecked.value
        
    }
    const sendCodeDisable=(errors)=>{
      // let consentCheck= document.querySelector('input[name="consent"]') as HTMLInputElement
      let firstName= document.querySelector('input[name="firstName"]') as HTMLInputElement
      let nrmlPass= document.querySelector('input[name="password"]') as HTMLInputElement
      let cnfmPass = document.querySelector('input[name="confirmPassword"]') as HTMLInputElement
      // let sourceCheck = document.querySelector('input[name="source"]') as HTMLInputElement
       if(Object.keys(errors).length > 0 || cnfmPass?.value == '' || firstName?.value ==''  || nrmlPass?.value=='' || initialValues["source"] == '' || isChecked.value == undefined || isChecked.value == false  ){
              // btnDisabled.value = true;
                return true;
            }else{
              // btnDisabled.value = false;
                return false
            }
        }
    const viewPassword = (val: number) => {
      if (val === 1) {
        return passwordHidden.value ? "password" : "text";
      }
      return confirmPasswordHidden.value ? "password" : "text";
    };
    const smileyRegex = /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/ug
    const schema = yup.object().shape({
      consent: yup.bool().required('Please provide your consent.'),
      firstName: yup
        .string()
        .strict(true).trim()
        .test("firstName", function (value: any) {
          localStorage.setItem("firstName", value);
          if (validations.verification.hasNum.test(value)) {
            return this.createError({
              message: "First name cannot include numbers",
            });
          } else if (validations.verification.hasWhitespace.test(value)) {
            return this.createError({
              message: "First name cannot include spaces",
            });
          } else if (validations.verification.hasSpecialChar.test(value)) {
            return this.createError({
              message: "First name cannot include special characters",
            });
          } else return true;
        })
        .min(2, "First name cannot be less than 2 characters")
        .max(50, "First name cannot be more than 50 characters")
        .required()
        .label("First Name"),

      lastName: yup
        .string()
        .strict(true)
        .test("lastName", function (value: any) {
          localStorage.setItem("lastName", value);
          if (validations.verification.hasNum.test(value)) {
            return this.createError({
              message: "Last name cannot include numbers",
            });
          } else if (validations.verification.hasWhitespace.test(value)) {
            return this.createError({
              message: "Last name cannot include spaces",
            });
          } else if (validations.verification.hasSpecialChar.test(value)) {
            return this.createError({
              message: "Last name cannot include special characters",
            });
          } else return true;
        })
        .min(2, "Last name cannot be less than 2 characters")
        .max(50, "Last name cannot be more than 50 characters")
        .required()
        .label("Last Name"),
      preferredName: yup
        .string()
        .strict(true)
        .test("preferredName", function (value: any) {
          localStorage.setItem("preferredName", value);
          if (validations.verification.hasNum.test(value)) {
            return this.createError({
              message: "Preferred name cannot include numbers",
            });
          } else if (validations.verification.hasWhitespace.test(value)) {
            return this.createError({
              message: "Preferred name cannot include spaces",
            });
          } else if (validations.verification.hasSpecialChar.test(value)) {
            return this.createError({
              message: "Preferred name cannot include special characters",
            });
          } else return true;
        })
        .max(50, "Preferred name cannot be more than 50 characters")
        .label("Preferred Name"),
      email: yup
        .string()
        .trim()
        .required()
        // .matches(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,}$/,"Not a valid email")
        //.email("Email must follow the exact format: name@example.com")
        .test(
          "Email",
          async function (value: any) {
           let prevEmailid = localStorage.getItem("emailid"); 
            let atposition = +value.indexOf("@");
            let dotposition = +value.lastIndexOf(".");
            if (value.length == 0) {
              return this.createError({ message: "Email is a required field" })
            } else if (!/^[^@]+@([a-zA-Z0-9-]{2,}(\.[a-zA-Z0-9-]{2,})+)$/.test(value)) {
              return this.createError({ message: "Not a valid email" })
            } else if (value.match(smileyRegex)) {
              return this.createError({ message: "Not a valid email" })
            } else if (atposition <= 1 || dotposition + 2 >= value.length) {
              return this.createError({ message: "Not a valid email" })
            } else if (atposition + 2 >= dotposition) {
              return this.createError({ message: "Not a valid email" })
            } else if  (prevEmailid!="" && prevEmailid!=value && !await validateEmailUserTable(value)) {
              return this.createError({ message: "Account already exists" })
            } else {
              localStorage.setItem("emailid", value);
              return true;   
            }     
        })
        .test("Emails", "Email cannot be null", (value: any) => {
          return resetEmail(value);
        })
        .label("Email"),
      confirmEmail: yup
        .string()
        .trim()
        .required("Confirm Email is required")
        .oneOf([yup.ref("email"), null], "Email must match"),

      password: yup
        .string()
        .trim()
        .required()
        .test(
          "Password",
          "Password must contain uppercase, lowercase, numbers and special characters.",
          function (value: any) {
            return validations.verification.password.test(value);
          }
        )
        .min(8)
        .label("Password"),

      confirmPassword: yup
        .string()
        .required("Confirm Password is required")
        .oneOf([yup.ref("password"), null], "Confirm Password must match"),
      referralCode: yup
        .string()
        .test("referralCode", function (value: any) {
          if (validations.verification.referralCode.test(value)) {
            return this.createError({
              message: "Referral code is invalid",
            });
          } else return true;
        })
        .label("Referral Code"),
      source: yup
        .string()
        .required("Source is a required field")
        .label("Source")
    });
    const resetEmail = async (value) => {
      if (value === "" || value === null) {
        localStorage.setItem("email", value);
        responseEmail.value.boolean = true;
      }
      return true;
    };
    const handleSourceSelect = (value) => {
      initialValues["source"] = value;
    }
    const validateEmailUserTable = async (value: any) => {
      localStorage.clear();
      checkEmail = value;
      const userNameResponse = await proxyApi.post("/checkUserName", {
        param: Base64.encode(JSON.stringify({ email: value.toLowerCase() })),
      })
      const emailResponse = await proxyApi.post("/checkEmail", {
        param: Base64.encode(JSON.stringify({ email: value.toLowerCase() })),
      })
      return userNameResponse.data.rowCount == 0 &&
        emailResponse.data.rowCount == 0
        ? true
        : false;
    }
    const validateUsername = async (value) => {
      if (
        value.toLowerCase() &&
        responseUsername.value.username != value.toLowerCase()
      ) {
        const result = await proxyApi.post("/checkUserName", {
          param: Base64.encode(
            JSON.stringify({ username: value.toLowerCase() })
          ),
        });
        responseUsername.value.username = value.toLowerCase();
        if (
          result.data.result.length > 0 &&
          result.data.result[0].userID != null
        ) {
          responseUsername.value.boolean = false;
          return false;
        }
        responseUsername.value.boolean = true;
        return true;
      } else if (responseUsername.value.username == value.toLowerCase()) {
        return responseUsername.value.boolean;
      }
      return true;
    };

    const signupTerms = async () => {
      // if (isTaxConsentEntered.value && acceptBranch.value) {
      if (acceptBranch.value) {
        errorReset();
        return true;
      }
      else {
        isPolicyMandatoryError.value = true;
        signUpTermError.value = "Digital Wallet Terms must be accepted";
        return false;
      }

    };

    const errorReset = () => {
      isPolicyMandatoryError.value = false;
      signUpTermError.value = "";
    };
    let signupFormData: any = {};

    const getClientLocationId = async () => {
      const locationId = route.query.locationId
      if (locationId) {
        return locationId
      } else {
        return signup.post("/getClientLocationId", encodeWithParam({ clientLocationName: route.query.name }))
          .then(({ data }) => {
            if (data.success) {
              return data.result[0].id
            }
          })
      }
    }

    const getQuestionIDandMapAns = async () => {
      proxyApi.post('/getFunnelQuestions', encodeWithParam({ step: 'ELIGIBILITY' })).then((response) => {
        const ansForFunnelQuestions = {}
        funnelQuestions.value = response.data.result
        payload.value.ans = []
        ansForFunnelQuestions["isOver18"] = "YES"
        ansForFunnelQuestions["isWalmartAssociate"] = "YES"
        ansForFunnelQuestions["howDidYouHearAboutOpportunity"] = initialValues.source
        for (const [key, value] of Object.entries(ansForFunnelQuestions)) {
          const question: EligibilityQuestion = funnelQuestions.value.find(q => q.key === key)!
          payload.value.ans.push({
            questionID: question.questionid,
            response: value,
          })
        }
      })
    }

    const handleInsFunnelAnswers = async () => {
       proxyApi.post('/insFunnelAnswers', encodeWithParam(payload.value))
        
    }

    const createAccount = async (formData: any) => {
      loading.value=true;
      if (await signupTerms()) {
        Object.keys(formData).map(
          (k) =>
          (formData[k] =
            typeof formData[k] == "string" ? formData[k].trim() : formData[k])
        );
        Object.assign(signupFormData, formData);

        if (
          // (await validateEmail(signupFormData?.email)) &&a
          (await validateEmailUserTable(signupFormData?.email)) &&
          (await validateUsername(signupFormData?.email))
        ) {
          getPhoneNumber();
          signupFormData.phone = phoneNumber.value;

          if(signupFormData.phone.length < 10){
              localStorage.clear();
              sessionStorage.clear();
              phoneEmpty.value = true;
              setTimeout(()=>{
                redirectTosignup()
            },2000)
             
          }else{
            sessionStorage.setItem("signupData", encode(signupFormData));
          let obj = {
            "username": signupFormData.email,
            "password": signupFormData.password,
            "email": signupFormData.email,
            "role": "PARTNER",
            "phone": signupFormData.phone,
            "firstName": signupFormData.firstName,
            "lastName": signupFormData.lastName,
            "preferredName": signupFormData.preferredName,
            "clientLocationID": await getClientLocationId(),
            "source": signupFormData.source,
            "taxConsent": signupFormData["TaxConsentDetails"]?.taxConsent,
            "signedOn": DateTime.now().setZone("America/Los_Angeles"),
            "referralCode": signupFormData.referralCode,
            "profileImage": undefined,
            "medium": route?.query?.medium ? route.query.medium : null,
            "campaign": route?.query?.campaign ? route.query.campaign : null
          };
          obj.firstName = encodeURI(obj.firstName)
          obj.lastName = encodeURI(obj.lastName)

          // console.log(obj);
          signup.post("/signupUser", encodeWithParam(obj))
            .then(async ({ data }) => {
              if (data.success) {
                await singletonObject(decode(data.result))
                appendRecruiticsScript();
                await trigerLogin();

                // gTagEvent("Complete Create Account", "Create Account", `partner_id: ${state.user.partnerId}`, true, `${Math.round((performance.now() - pageTimeStart.value) / 1000)} seconds`)
                gTagEvent("Accepted Walmart Privacy Policy", "Acknowledgements", `partner_id: ${state.user.partnerId}`)
                gTagEvent("Selected E-Tax Consent", "Acknowledgements", `partner_id: ${state.user.partnerId}`)
                gTagEvent("Accepted Digital Wallet Terms", "Acknowledgements", `partner_id: ${state.user.partnerId}`)

                // router.push({ 
                //   name: 'ca-login', 
                // })
              }else{
                phoneEmpty.value = true;
                  setTimeout(()=>{
                    redirectTosignup()
                },2000)
              }
            })
          }
          
        } else {
          loading.value=false
          micromodal.show("duplicate-user");
        }
      }
    };
    const trigerLogin = async () => {
      let obj = {
        username: signupFormData.email,
        password: signupFormData.password,
        isSignup: true
      }
      await authenticationService.login(encodeWithParam(obj)).then(async () => {
        localStorage.setItem("username", signupFormData.username);
        await getQuestionIDandMapAns();
        await thresholdValidation();
        await isValidLocation();
        await handleInsFunnelAnswers();

      })
    };

    const singletonObject = async (data) => {
      let userObj = data.user;
      mutation.user.setPartnerId(userObj?.partnerID ? userObj.partnerID : "");
      mutation.user.setUserId(userObj?.userID ? userObj.userID : "");
      mutation.user.setEmail(signupFormData.email);
      mutation.user.setFirstName(signupFormData.firstName);
      mutation.user.setLastName(signupFormData.lastName);
      mutation.user.setLocationId(route.query.locationId as string);
      mutation.user.setLocationName(route.query.name as string);
      mutation.user.setLocationPartnerId(userObj?.clientLocationPartnerID ? userObj.clientLocationPartnerID : "");
      mutation.user.setUsername(signupFormData.username);
      mutation.user.setPhone(signupFormData.phone);
      mutation.mergeTags.setPartnerFullName(`${signupFormData.firstName} ${signupFormData.lastName}`);
      mutation.mergeTags.setPartnerFirstName(signupFormData.firstName);
      mutation.mergeTags.setPartnerLastName(signupFormData.lastName);
      mutation.mergeTags.setPartnerEmail(signupFormData.email?.toLowerCase().trim());
      sessionStorage.removeItem("signupData")
    };

    const appendRecruiticsScript = () => {
      CommonService.triggerRecruticsScript();
    };
    const thresholdValidation = async () => {
      let thresholdCheck:any= await signup.post("/thresholdCheck", encodeWithParam({ clientLocationID: await getClientLocationId() }))
      if(thresholdCheck.data.success && !thresholdCheck.data.allowSignup){
        proxyApi.post('/addApplicationStatus', encodeWithParam({ status: FunnelStatus.ENROLLMENT_ON_HOLD}));
        return router.push({ name: 'LocationUpdateCa', query: { locationId: state.user.locationId } })
      }
      else{
        isAllowedtoSignUp.value = true
        proxyApi.post('/addApplicationStatus', encodeWithParam({ status: FunnelStatus.PERSONAL_DETAILS_PENDING}));
      }

    }
    const isValidLocation = async () => {
      proxyApi.post("/validateLocationStatus", encodeWithParam({ clientLocationID: await getClientLocationId() })).then(async ({ data }) => {
        if (data.success) {
          proxyApi.post('/addApplicationStatus', encodeWithParam({ status: FunnelStatus.CREATED}));
          if(!isAllowedtoSignUp.value){
            let thresholdCheck:any= await signup.post("/thresholdCheck", encodeWithParam({ clientLocationID: await getClientLocationId() }))
          if(thresholdCheck.data.success && !thresholdCheck.data.allowSignup && data.result[0].isRecruiting){
            return router.push({ name: 'LocationUpdateCa', query: { locationId: state.user.locationId } })
          }
          setStatus("ELIGIBILITY", "INCOMPLETE")
          if (!data.result[0].isRecruiting) {
            setStatus("APPLICATION", "ONHOLD")
            // proxyApi.post('/addApplicationStatus', encodeWithParam({ stepName: 'APPLICATION', stepStatus: 'ONHOLD' }));
            // return router.push({ name: 'locationUpdate', query: { locationId: state.user.locationId } })
            proxyApi.post('/addApplicationStatus', encodeWithParam({ status: FunnelStatus.ENROLLMENT_ON_HOLD}));
            return router.push({ name: 'LocationUpdateCa', query: { locationId: state.user.locationId } })
          }
          }
          if (checklistMethods.isMobile()) {
            router.push({
              name: 'ca-login',
            })
          } else {
            router.push({
              name: 'ca-login',
            })
          }
          // }
        }
      });
    }

    return { 
      btnDisabled,
      initialValues,
      phoneEmpty,
      passwordHidden,
      confirmPasswordHidden,
      formError,
      duplicateUser,
      openModal,
      togglePassword,
      viewPassword,
      schema,
      // walmartPolicy,
      // walmartTaxConfirmation,
      createAccount,
      // acceptPayProcessor,
      acceptBranch,
      // paymentModal,
      phoneNumber,
      isPolicyMandatoryError,
      signUpTermError,
      // isTaxConsentEntered,
      // acceptWalmartPolicy,
      sourceOptions,
      handleSourceSelect,
      // pageTimeStart,
      sendCodeDisable,
      handleConsent,
      isChecked,
      loading
    }
  }
});
