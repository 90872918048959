

import { defineComponent, onMounted, ref } from "vue";
import faqItem from "./faqItem.vue";
// import IconArrowLeft from "@/components/icons/IconArrowLeft.vue";
import router from "@/router";
import { proxyApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import Loader from "@/components/modals/Loader.vue";
import { state } from "@/harlem/stores";
import { gTagEvent } from "@/util/GoogleAnalytics";



export default defineComponent({
  name: "ca-faq",
  components: { faqItem,  Loader},
  setup() {
    const currentSelection = ref(0);
    const dataFetched = ref(false);
    const questionnaire = ref<any>([]);

    function onClickSupport(){
      console.log("support btn clicked");
    }

    function expandSection(data:number){
      (currentSelection.value == data)? currentSelection.value = 0 : currentSelection.value = data;
    }
    function redirectToHome(){
      // router.push({
      //   name:"ca-earnings-account-home"
      // })
      router.back()
    }
    const redirectSparkDriver = () => {
      gTagEvent("Open Drive4Spark FAQs from Support Page", "Dashboard", `partner_id: ${state.user.partnerId}`)
      window.open("https://drive4spark.walmart.com/ca/faq", "_blank");
    } 
    async function fetchData(){
      dataFetched.value = false;

      try {
        // groupId 1, will fetch pre-onbarding content
        const resp = await proxyApi.post("/getFaqs", encodeWithParam({ groupId: 1 }));
        if(resp.data.success){
          questionnaire.value = resp.data.result;
          dataFetched.value = true;
        }else{
          questionnaire.value = [];
        }
      } catch (err) {
        questionnaire.value = [];
        console.log("error: ", err);
      }
    }

    onMounted(()=>{
      fetchData();
    })

    return { onClickSupport, redirectToHome, expandSection, redirectSparkDriver, questionnaire, currentSelection, dataFetched }
  },
})
