
export default {
  name: "IconCheck",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
