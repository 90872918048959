export const validations = {
    verification: {
        hasNum: new RegExp(/[0-9]/),
        hasWhitespace: new RegExp(/[\s]/),
        //eslint-disable-next-line
        hasSpecialChar: new RegExp(/[^a-zA-Z0-9 '‘’\-~éàèùâêîôûçëïü]|(^'.*)|(.*'$)/),
        password: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*:;./?&_-])[A-Za-z\d@#$!%*:;./?&_-]{8,}$/),
        referralCode: new RegExp(/[^a-zA-Z0-9]/)
    },
    businessDetails: {
        //eslint-disable-next-line
        ein: new RegExp(/^\d{2}\-?\d{7}$/)
    }
}