import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = {
  class: "modal-window_overlay",
  tabindex: "-1",
  "data-micromodal-close": ""
}
const _hoisted_3 = {
  class: "micro-modal_container",
  id: "modal-fixed-width",
  role: "dialog",
  "aria-modal": "true",
  "aria-labelledby": "modal-title"
}
const _hoisted_4 = { class: "modal-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", {
      id: _ctx.$props.id,
      class: "modal",
      "aria-hidden": "true"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ])
      ])
    ], 8, _hoisted_1)
  ]))
}