<template>
  <svg v-svg="'icon-history'" size="20 20" />
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "IconHistory",
});
</script>
