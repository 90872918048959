<template>
  <div class="field-group">
    <div class="field">
      <label :for="name" class="dropdown-label"
        >{{ label
        }}<span class="required-field" v-if="required">*</span></label
      >
      <div class="select-container" :class="{ 'select-has-error': hasError }">
        <Field
          as="select"
          :id="id"
          :name="name"
          :validate-on-mount="validateOnMount"
          :disabled="disabled"
        >
          <option value="">{{ placeholder ? placeholder : 'Select' }}</option>
          <template v-for="option in options" :key="option.value">
            <option :value="option.value">{{ option.text }}</option>
          </template>
        </Field>
      </div>
      <FieldError :name="name" />
    </div>
  </div>
</template>
<script>
import { Field } from "vee-validate";
import { FieldError } from "@/components";
import { defineComponent } from "vue";
export default defineComponent({
  name: "SelectorValidated",
  components: { Field, FieldError },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    validateOnMount: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasError: {
      type: Boolean,
      required: false,
      default: false,
    },
    options: {
      type: Array,
      required: true,
    },
     disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
    }
  },
});
</script>
