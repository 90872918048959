<template>
    <div v-if="!isMobile()" class="desktop-module_checklist">
        <Checklist :step="step" :stepIndex="stepIndex" />
    </div>
</template>

<script>
import { defineComponent } from "vue";
import Checklist from "./Checklist.vue";
import checklistMethods from "./Helpers/Index";

export default defineComponent({
    name: "InitChecklist",
    props: ["step", "stepIndex"],
    components: {
        Checklist
    },
    methods: {
        isMobile() {
            return checklistMethods.isMobile()
        }
    }
})
</script>