
import { IconArrowDown, IconArrowUp } from "../index";

export default {
  name: "IconArrowUpDown",
  components: { IconArrowUp, IconArrowDown },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
};
