<template>
  <div class="action-left">
    <router-link v-if="goto" :to="{ name: goto }" class="back">
      <svg v-svg="'icon-arrow-left'" size="18 18" />
      {{ text }}
    </router-link>
    <a v-else class="back" :href="href" @click="emit('arrow-clicked')">
      <svg v-svg="'icon-arrow-left'" size="18 18" />
      {{ text }}
    </a>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "IconArrowLeft",
  emits: ["arrow-clicked"],
  props: {
    text: {
      type: String,
      required: false,
      default: "",
    },
    goto: {
      type: String,
      required: false,
      default: "",
    },
    href: {
      type: String,
      required: false,
      default: "",
    },
  },
});
</script>
