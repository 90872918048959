const STEPS_MAP = {
  SIGNUP: "signup",
  VERIFICATION: "verification",
  ELIGIBILITY: "eligibility",
  PERSONALDETAILS: "personalDetails",
  DOCUMENTS: "documents",
  PAYMENTAGREEMENT: "paymentinfo",
  VETTING: "vetting",
  INTERVIEW_1: "interview1",
  INTERVIEW_2: "interview2",
  SCREENING: "screening",
  APPROVED: "approved",
  COMPLETED: "completed",
} as any;
import router from "@/router";
import { state } from "@/harlem/stores";
export const nextStep = (step: string) => {
  switch (step.toUpperCase()) {
    case "SIGNUP":
      return STEPS_MAP["VERIFICATION"];
    case "VERIFICATION":
      return STEPS_MAP["ELIGIBILITY"];
    case "PERSONALDETAILS":
      return STEPS_MAP["PERSONALDETAILS"];
    case "BUSINESSDETAILS":
      return STEPS_MAP["DOCUMENTS"];
    case "DOCUMENTS":
      return STEPS_MAP["COMPLETED"];
    case "PAYMENTAGREEMENT":
      return STEPS_MAP["PAYMENTAGREEMENT"];
  }
};

export const currentStep = (step: string) => {
  switch (step.toUpperCase()) {
    case "SIGNUP":
      return STEPS_MAP["SIGNUP"];
    case "VERIFICATION":
      return STEPS_MAP["VERIFICATION"];
    case "PERSONALDETAILS":
      return STEPS_MAP["PERSONALDETAILS"];
    case "PERSONAL_DETAILS":
      return STEPS_MAP["PERSONALDETAILS"];
    case "DOCUMENTS":
      return STEPS_MAP["DOCUMENTS"];
    case "PAYMENT_AGREEMENT":
      return STEPS_MAP["PAYMENTAGREEMENT"];
    case "COMPLETED":
      return STEPS_MAP["COMPLETED"];
  }
};

export const isPending = (step, status) => {
  const pendingStatus =["SIGNUP","VERIFICATION","ELIGIBILITY","PERSONAL_DETAILS","BUSINESS_DETAILS","DOCUMENTS","PAYMENT_AGREEMENT"];
  if (pendingStatus.includes(step)) {
    // route in-progress candidates to dashboard if they try to access other dashboard content
  }
}

export const setStatus = (step, status) => {
  const progress = {
    step: step,
    status: status
  }
  localStorage.setItem("progress", JSON.stringify(progress))
};