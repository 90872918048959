import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7733b811"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.string.header), 1),
    _createElementVNode("p", null, _toDisplayString(_ctx.string.subheader), 1),
    _createElementVNode("a", {
      target: "_blank",
      href: `/faq/directDeposit?name=${_ctx.locationName}`
    }, _toDisplayString(_ctx.string.learnMore), 9, _hoisted_1)
  ], 64))
}