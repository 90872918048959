import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42dcd9a9"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["placeholder"]
const _hoisted_2 = { class: "confirmNumber" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_FieldError = _resolveComponent("FieldError")!
  const _component_AchNumberText = _resolveComponent("AchNumberText")!
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", {
      class: _normalizeClass({ 'error': _ctx.errors[_ctx.type] || _ctx.errors[_ctx.confirmNumber] })
    }, _toDisplayString(_ctx.capitalType) + " number ", 3),
    _createElementVNode("div", {
      class: _normalizeClass(["numbers", { 'error': _ctx.errors[_ctx.type] || _ctx.errors[_ctx.confirmNumber] }])
    }, [
      _createVNode(_component_Field, {
        type: "text",
        name: _ctx.type,
        modelValue: _ctx.originalBankNumber,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.originalBankNumber) = $event)),
        hidden: ""
      }, null, 8, ["name", "modelValue"]),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        placeholder: `${_ctx.capitalType} number`,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.maskedBankNumber) = $event)),
        onFocusout: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.maskBankNumber && _ctx.maskBankNumber(...args))),
        onFocus: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.showMaskedBankNumber && _ctx.showMaskedBankNumber(...args)))
      }, null, 40, _hoisted_1), [
        [_vModelText, _ctx.maskedBankNumber],
        [_directive_maska, '#'.repeat(_ctx.maxlength)]
      ]),
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createVNode(_component_Field, {
          type: "text",
          name: _ctx.confirmNumber,
          placeholder: `Confirm ${_ctx.type} number`,
          onCopy: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"])),
          onPaste: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"]))
        }, null, 8, ["name", "placeholder"]), [
          [_directive_maska, '#'.repeat(_ctx.maxlength)]
        ])
      ])
    ], 2),
    _createVNode(_component_FieldError, { name: _ctx.type }, null, 8, ["name"]),
    (!_ctx.errors[_ctx.type])
      ? (_openBlock(), _createBlock(_component_FieldError, {
          key: 0,
          name: _ctx.confirmNumber
        }, null, 8, ["name"]))
      : _createCommentVNode("", true),
    (!_ctx.errors[_ctx.type] && !_ctx.errors[_ctx.confirmNumber])
      ? (_openBlock(), _createBlock(_component_AchNumberText, {
          key: 1,
          type: _ctx.type,
          onOpen: _cache[6] || (_cache[6] = ($event: any) => (_ctx.micromodal.show('account-details')))
        }, null, 8, ["type"]))
      : _createCommentVNode("", true)
  ], 64))
}