<template>
    <transition name="snackbar">
      <div v-if="active" class="snackbar" :class="classes" :style="{ 'background-color': color }">
        <div class="snackbar__wrap">
          <div class="snackbar__body">
            <slot></slot>
          </div>
        </div>
      </div>
    </transition>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
 name: "Snackbar",
 props: {
    active: {
      type: Boolean,
      default: false
    },
    timeout: {
      type: Number,
      default: 5000
    },
    infinity: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'bottom-left'
    },
    color: {
      type: String
    }
  },
  data() {
    return {
      timer: null  
    }
  },
  watch: {
    active() {
      this.setTimer()
    }
  },
  computed: {
    classes() {
      return {
        [`snackbar--${this.position}`]: true
      } 
    }
  },
  methods: {
    setTimer() {
      
      if (this.timer) clearTimeout(this.timer)
      
      if (this.infinity) return;
      
      this.timer = setTimeout(() => {
        this.$emit('update:active', false)
      }, this.timeout)
      
    }
  }
})
</script>
<style lang="scss">
.close-btn {
  padding: 8px 12px;
  margin: -8px -6px -8px 20px;
  color: #FFFFFF;
  font-family: inherit;
  font-size: inherit;
  text-transform: uppercase;
  border: 0;
  border-radius: 3px;
  background-color: transparent;
  cursor: pointer;
  outline: 0;
  transition: background-color .25s ease;
  
  &:hover {
    background-color: rgba(#448aff, .1);
  }
  &:active {
    background-color: rgba(#448aff, .2);
    transition-duration: .35s;
  }
}

.snackbar {
  position: fixed;
  display: flex;
  will-change: transform, opacity;
  z-index: 14;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1), 0px 5px 10px 3px rgba(0, 0, 0, 0.15);
  width: 343px;
  height: 82px;
  margin: 0 auto;
  &--bottom-right {
    right: 20px;
    bottom: 20px;
  }
  
  &--bottom-center {
    left: 0;
    right: 0;
    bottom: 80px;
  }
  
  &--bottom-left {
    left: 20px;
    bottom: 20px;
  }
  
  &--top-right {
    right: 20px;
    top: 20px;
  }
  
  &--top-center {
    left: 0;
    right: 0;
    top: 0;
  }
  
  &--top-left {
    left: 20px;
    top: 20px;
  }
  
  &__wrap {
    min-width: 100%;
    height: 75px;
    margin: 0 auto;
    padding: 15px;
    box-sizing: border-box;
  }
  &__body {    
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 1.5;
    color: #fff;
  }
}

.snackbar-enter-active, .snackbar-leave-active {
  transition: all 250ms cubic-bezier(.4,0,.2,1);
}

.snackbar--bottom-center.snackbar-enter,
.snackbar--bottom-center.snackbar-leave-to {
  opacity: .5;
  transform: translate3d(0, 100%, 0);
}

.snackbar--top-center.snackbar-enter,
.snackbar--top-center.snackbar-leave-to {
  opacity: .5;
  transform: translate3d(0, -100%, 0);
}

.snackbar--bottom-left.snackbar-enter,
.snackbar--bottom-left.snackbar-leave-to,
.snackbar--bottom-right.snackbar-enter,
.snackbar--bottom-right.snackbar-leave-to {
  opacity: .5;
  transform: translate3d(0, calc(100% + 20px), 0);
}
.snackbar--top-left.snackbar-enter,
.snackbar--top-left.snackbar-leave-to,
.snackbar--top-right.snackbar-enter,
.snackbar--top-right.snackbar-leave-to {
  opacity: .5;
  transform: translate3d(0, calc(-100% - 20px), 0);
}
</style>