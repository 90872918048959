import { mutation } from "@/harlem/stores/mergeTags.store";

export const setMergeTagDate = mutation<string, any>(
  "setMergeTagDate",
  (state, payload) => {
    state.date = payload;
  }
);

export const setPartnerFullName = mutation<string, any>(
  "setPartnerFullName",
  (state, payload) => {
    state.partner.name = payload;
  }
);

export const setPartnerFirstName = mutation<string, any>(
  "setPartnerFirstName",
  (state, payload) => {
    state.partner.firstName = payload;
  }
);

export const setPartnerLastName = mutation<string, any>(
  "setPartnerLastName",
  (state, payload) => {
    state.partner.lastName = payload;
  }
);

export const setPartnerContractDate = mutation<string, any>(
  "setPartnerContractDate",
  (state, payload) => {
    state.partner.contractDate = payload;
  }
);

export const setDateOfBirth = mutation<string, any>(
  "setDateOfBirth",
  (state, payload) => {
    state.partner.dateOfBirth = payload;
  }
);

export const setPartnerPhone = mutation<string, any>(
  "setPartnerPhone",
  (state, payload) => {
    state.partner.phone = payload;
  }
);

export const setPartnerEmail = mutation<string, any>(
  "setPartnerEmail",
  (state, payload) => {
    state.partner.email = payload;
  }
);

export const setPartnerSSN = mutation<string, any>(
  "setPartnerSSN",
  (state, payload) => {
    state.partner.ssn = payload;
  }
);

export const setPartnerSignature = mutation<string, any>(
  "setPartnerSignature",
  (state, payload) => {
    state.partner.signature = payload;
  }
);

export const setPartnerInitials = mutation<string, any>(
  "setPartnerInitials",
  (state, payload) => {
    state.partner.initials = payload;
  }
);

export const setPartnerAddress = mutation<any, any>(
  "setPartnerMergeAddress",
  (state, payload) => {
    state.partner.address = { ...state.partner.address, ...payload };
  }
);

export const setDDIMergTags = mutation<any, any>(
  "setDDIMergeTags",
  (state, payload) => {
    state.ddi = { ...state.ddi, ...payload };
  }
);

export const setlicenseMergeTags = mutation<any, any>(
  "setLicenseMergeTags",
  (state, payload) => {
    state.partner.license = { ...state.partner.license, ...payload };
  }
);

export const setVehicleMergeTags = mutation<any, any>(
  "setVehicleMergeTags",
  (state, payload) => {
    state.partner.vehicle = { ...state.partner.vehicle, ...payload };
  }
);
