import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "icon" }
const _hoisted_2 = ["width", "height"]
const _hoisted_3 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("svg", {
      xmlns: "http://www.w3.org/2000/svg",
      width: _ctx.width ? _ctx.width : '22',
      height: _ctx.height ? _ctx.width : '20',
      viewBox: "0 0 22 20",
      fill: "none"
    }, [
      _createElementVNode("path", {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        fill: _ctx.color,
        d: "M21.3525 15.4062L13.2138 1.63306C12.9931 1.25962 12.6816 0.948074 12.3082 0.727407C11.0855 0.00492978 9.50867 0.410403 8.78619 1.63306L0.647534 15.4062C0.413418 15.8024 0.289917 16.2541 0.289917 16.7143C0.289917 18.1345 1.44118 19.2858 2.86135 19.2858H19.1387C19.5989 19.2858 20.0506 19.1623 20.4468 18.9281C21.6695 18.2057 22.075 16.6288 21.3525 15.4062ZM11.4361 2.20328C11.5605 2.27684 11.6644 2.38069 11.7379 2.50516L19.8766 16.2783C20.1174 16.6858 19.9823 17.2114 19.5747 17.4523C19.4427 17.5303 19.2921 17.5715 19.1387 17.5715H2.86135C2.38796 17.5715 2.0042 17.1877 2.0042 16.7143C2.0042 16.5609 2.04537 16.4103 2.12341 16.2783L10.2621 2.50516C10.5029 2.09761 11.0285 1.96246 11.4361 2.20328ZM10.1429 5.57148H11.8571V12.4286H10.1429V5.57148ZM11.8571 14.1429H10.1429V15.8572H11.8571V14.1429Z"
      }, null, 8, _hoisted_3)
    ], 8, _hoisted_2))
  ]))
}