import { mutation } from '@/harlem/stores/business.store'

export const setBusinessFlag = mutation<boolean>(
  'setBusinessFlag', (state, payload) =>
    state.isBusinessSelected = payload
)

export const setName = mutation<string>(
  'setName', (state, payload) =>
    state.name = payload
)

export const setEin = mutation<string>(
  'setEin', (state, payload) => 
    state.ein = payload
)

export const setAddress1 = mutation<string>(
  'setAddress1', (state, payload) =>
    state.address1 = payload
)

export const setAddress2 = mutation<string>(
  'setAddress2', (state, payload) =>
    state.address2 = payload
)

export const setCity = mutation<string>(
  'setCity', (state, payload) =>
    state.city = payload
)

export const setCounty = mutation<string>(
  'setCounty', (state, payload) =>
    state.county = payload
)

export const setState = mutation<string>(
  'setState', (state, payload) =>
    state.state = payload
)

export const setZipcode = mutation<string>(
  'setZipcode', (state, payload) =>
    state.zipcode = payload
)

export const setTaxClassification = mutation<string>(
  'setTaxClassification', (state, payload) =>
    state.taxClassification = payload
)

export const setTaxClassificationOther = mutation<string>(
  'setTaxClassificationOther', (state, payload) =>
    state.taxClassificationOther = payload
)