
import { computed, defineComponent, ref } from "vue";
import BaseModal from "@/components/modals/BaseModal.vue";

export default defineComponent({
  name: "LearnMore",
  components: { BaseModal },
  setup() {
    const showIframe = ref(true);
    const whiteLabel = sessionStorage.getItem("white_label")
      ? sessionStorage.getItem("white_label")
      : "";
    const closeIFrame = () => {
      //terminate playback if modal is closed without stopping the video
      showIframe.value = false;
      //re-enable I-frame so that the video will play a second time
      setTimeout(() => {
        showIframe.value = true;
      }, 300);
    };

    const videoSrc = computed(() => {
      return whiteLabel == "customCss"
        ? "https://player.vimeo.com/video/711851297?h=e1976752ab"
        : "https://player.vimeo.com/video/346939359";
    });
    return { showIframe, closeIFrame, videoSrc };
  },
});
