
import { defineComponent, ref, computed, onMounted } from "vue";
import { authApi } from "@/http/http.config";
import * as yup from "yup";
import Base64 from "base-64"
import { state } from "@/harlem/stores";
import { mutation } from "@/harlem/mutation";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { TextFieldValidated, IconWarningOctagon } from "@/components";
import { Form } from "vee-validate";
import router from "@/router";

export default defineComponent({
  name: "ForgotPassword",
  components: {
    IconWarningOctagon,
    TextFieldValidated,
    Form,
    PulseLoader,
  },
  setup() {
    const emailAddress = ref("");
    const loading = ref(false);
    const sent = ref(false);
    const resend = ref(false);
    const validationFailed = ref("");
    const verificationCode = ref("");

    onMounted(() => {
    const userId = window.location.search.split("?userId=")[1]
    const decryptedEmail =  Base64.decode(userId)
    const username = JSON.parse(decryptedEmail);
    emailAddress.value = username.userId;
    });

    const sendEmail = async () => {
      loading.value = true;
      await authApi.post("/auth/sendResetOTP", { email: Base64.encode(emailAddress.value.toLowerCase())})
        .then((result: any) => {
          sent.value = true;
          mutation.user.setUserId(Base64.decode(result?.data.userID));
          loading.value = false;
          setTimeout(() => {
            sent.value = false;
            resend.value = true;
          }, 3000);
        })
        .catch(error => {
          sent.value = false;
          loading.value = false
        });
    };

 
    const validateToken = (data: Record<string, string>) => {
      return authApi
        .post("/auth/otpValidate", {
          param: Base64.encode(JSON.stringify({
            userID: state.user.userId,
            otp: data.Verification
          }))
        })
        .then(({ data }) => {
            console.log('this is data', data)
          if (data) {
            router.push({ name: "updatePassword" });
          } else {
            verificationCode.value = "";
            validationFailed.value = "Invalid verification code.";
          }
        });
    };

    const validationSchema = yup.object().shape({
      verification: yup
        .number()
        .integer()
        .min(6)
        .max(6)
        .required()
        .label("Enter OTP"),
    });

    const verifyButton = computed(() => {
      if (sent.value) {
        return { label: "Sent!", class: "button-send", disabled: true };
      } else if (resend.value) {
        return {
          label: "Resend Code",
          class: "button-send",
          disabled: false,
        };
      } else {
        return { label: "Send Code", class: "button", disabled: false };
      }
    });

    return { 
      sendEmail, 
      emailAddress, 
      verifyButton, 
      sent, 
      resend, 
      validationSchema, 
      validationFailed, 
      verificationCode, 
      validateToken,
      loading 
    };
  },
});
