
import { defineComponent } from "vue";
import { fileApi } from "@/http/http.config";

export default defineComponent({
  name: "FileUploader",
  emits: ["change"],
  props: {
    label: {
      type: String,
      require: false,
      default: "",
    },
    userId: {
      type: String,
      required: true,
    },
    clientId: {
      type: String,
      require: false,
      default: "",
    },
    clientLocationId: {
      type: String,
      require: false,
      default: "",
    },
    accepts: {
      type: String,
      required: false,
      default: "image/*",
    },
    filename: {
      type: Blob,
      required: true,
      default: null,
    },
  },
  setup(props, { emit }) {
    const uploadFile = (event: Event) => {
      const formData = new FormData();
      if (
        !(event.target instanceof HTMLInputElement) ||
        (event.target as HTMLInputElement).files === null
      ) {
        throw new TypeError("There was a problem uploading the file");
      }

      const file = (event.target as HTMLInputElement).files![0];
      formData.append("clientID", props.clientId || "");
      formData.append("clientLocationID", props.clientLocationId || "");
      formData.append("userID", props.userId);
      formData.append("filename", file.name);
      formData.append("file", file as Blob);

      fileApi
        .post("/files", formData)
        .then(({ data }) => emit("change", data))
        .catch((err) => console.error(err));
    };

    return {
      uploadFile,
    };
  },
});
