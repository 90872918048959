
import { computed, defineComponent, nextTick, onMounted, Ref, ref } from "vue";
import { Field } from "vee-validate";
import { FieldError } from "@/components";
import { create, maska } from "maska";

export default defineComponent({
  name: "DateFieldValidated",
  components: { Field, FieldError },
  directives: { maska },
  props: {
    maxDate: {
      type: Date,
      required: false,
    },
    minDate: {
      type: Date,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    validateOnMount: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    modelValue: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: "mm/dd/yyyy",
    },
    typeable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:modelValue", "change"],
  setup(props, { emit }) {
    const model = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });
    const calendar: Ref<Record<string, any> | null> = ref(null);

    onMounted(() => {
      nextTick(() => {
        let container = document.getElementById(
          `${props.name}_input_container`
        );
        if (container) {
          let inputs = container.getElementsByTagName("input");
          create(inputs[1], { mask: "##-##-####" });
        }
      });
    });

    const calendarConfig = {
      allowInput: props.typeable,
      maxDate: props.maxDate,
      minDate: props.minDate,
      dateFormat: "Y-m-d",
      altInput: true,
      altFormat: "d-m-Y",
      clickOpens: false,
    };
    const inputValueRef = ref("");

    const updateOnBlur = (value) => {
      if (value.length === 0) {
        emit("update:modelValue", value[0]);
        emit("change", value[0]);
      }
    };

    const toggleCalendar = () => {
      console.log(calendar);
      if (calendar.value) {
        const fp = calendar.value.getElem()._flatpickr;
        console.log(fp);
        fp.toggle();
      }
    };
    return {
      inputValueRef,
      model,
      updateOnBlur,
      toggleCalendar,
      calendar,
      calendarConfig,
    };
  },
});
