
import { defineComponent, ref } from "vue";
import { Button } from "@/components/buttons/button";

export default defineComponent({
  name: "ButtonBar",
  emits: ["on-select"],
  props: {
    buttons: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const selectedButtonId = ref((props.buttons[0] as Button).id || "");

    const select = (button: Button) => {
      selectedButtonId.value = button.id;
      emit("on-select", button);
    };

    return { selectedButtonId, select };
  },
});
