import { createStore } from '@harlem/core'

const STATE = {
	name: '',
	ein: '',
	address1: '',
	address2: '',
	city: '',
	state: '',
	zipcode: '',
	county: '',
	taxClassification: '',
	taxClassificationOther: ''
}

const restoreState = () => {
  const userState = sessionStorage.getItem('ddi:business')

  if (userState) return JSON.parse(userState)
	return STATE
}

export const { state, mutation } = createStore(
  'business',
  restoreState()
)
