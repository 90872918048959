import { state as user } from "./user.store";
import { state as payment } from "./payment.store";
import { state as application } from "./application.store";
import { state as funnel } from "./funnel.store";
import { state as signup } from "./signup.store";
import { state as mergeTags } from "./mergeTags.store";
import { state as business } from './business.store'
export const state = {
  user,
  payment,
  application,
  funnel,
  signup,
  mergeTags,
  business,
};
