<template>
    <walmart-privacy-policy @click="closeModal" />
</template>

<script>
  import WalmartPrivacyPolicy from "@/components/modals/WalmartPrivacyPolicy.vue";
import router from "@/router";
  import micromodal from "micromodal";
  import { onMounted } from "vue";
export default {
    name : 'PrivacyStatement',
    components: {WalmartPrivacyPolicy},
    setup(){
        onMounted(() => {
            micromodal.show("walmart-privacy-policy");            
        })
        const closeModal = ()=>{
            micromodal.close("walmart-privacy-policy");
            router.back()
        }
        return {
            closeModal
        }
    }
}
</script>