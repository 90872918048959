
import { defineComponent } from "vue";
import { Field } from "vee-validate";
import { FieldError } from "@/components";

export default defineComponent({
  name: "YearSelect",
  components: { Field, FieldError },
  props: {
    label: {
      type: String,
      required: true,
      default: "Label",
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    validateOnMount: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
