
export const  FunnelStages= {
    ENROLLMENT: "ENROLLMENT",
    EARNINGS_METHOD: "EARNINGS METHOD",
    INSURANCE: "INSURANCE",
    RISK_MITIGATION: "RISK_MITIGATION",
    APPLICATION: "APPLICATION",
    WITHDRAWN : "WITHDRAWN"
}

export const Stages = [
    { id:101,name: FunnelStages.ENROLLMENT, label: "Enrollment"},
    { id:102,name: FunnelStages.EARNINGS_METHOD, label: "Earnings Method"},
    { id:103,name: FunnelStages.INSURANCE, label: "Insurance"},
    { id:104,name: FunnelStages.RISK_MITIGATION,label: "Risk Mitigation"},
    { id:105,name: FunnelStages.APPLICATION, label:"Application"},
    { id:106,name: FunnelStages.WITHDRAWN, label:"Withdrawn"}
]

export const FunnelSteps = {

    ACCOUNT_CREATION: "Account Creation",
    PERSONAL_DETAILS: "Personal Details",
    IDV:"IDV",
    DOCUMENTS:"Documents",
    INSURANCE_UPLOAD:"Insurance Upload",
    PAYMENT_AGREEMENT: "Payment Agreement",
    INSURANCE_VERFICATION:"Insurance Verification",
    MVR:"MVR",
    CBC:"CBC",
    APPLICATION_COMPLETE:"Application Complete",
    APPLICATION_APPROVED:"Application Approved",
    WITHDRAWN : "Withdrawn"
    
}

export const Steps = [
    {id:201, name: FunnelSteps.ACCOUNT_CREATION,label: "Account Creation",stageName: FunnelStages.ENROLLMENT},
    {id:202, name: FunnelSteps.PERSONAL_DETAILS,label: "Personal Details",stageName: FunnelStages.ENROLLMENT},
    {id:203, name: FunnelSteps.IDV,label:"Idv",stageName: FunnelStages.ENROLLMENT },
    {id:204, name: FunnelSteps.DOCUMENTS,label: "Documents",stageName:FunnelStages.ENROLLMENT},
    {id:205, name: FunnelSteps.INSURANCE_UPLOAD,label: "Insurance Upload",stageName: FunnelStages.ENROLLMENT },
    {id:206, name: FunnelSteps.PAYMENT_AGREEMENT,label: "Earnings Provider",stageName: FunnelStages.EARNINGS_METHOD},
    {id:207, name: FunnelSteps.INSURANCE_VERFICATION,label: "Insurance Verification",stageName:FunnelStages.INSURANCE },
    {id:208,name: FunnelSteps.MVR,label: "Mvr",stageName:FunnelStages.RISK_MITIGATION },
    {id:209,name: FunnelSteps.CBC,label: "Cbc",stageName:FunnelStages.RISK_MITIGATION },
    {id:210,name: FunnelSteps.APPLICATION_COMPLETE,label: "Complete",stageName:FunnelStages.APPLICATION },
    {id:211,name: FunnelSteps.APPLICATION_APPROVED,label: "Approved",stageName:FunnelStages.APPLICATION },
    {id:212,name: FunnelSteps.WITHDRAWN,label: "Withdrawn",stageName:FunnelStages.WITHDRAWN },

]

export const FunnelStatus = {
    CREATED : "Created",
    ENROLLMENT_ON_HOLD: "Enrollment On-Hold",
    ENROLLMENT_COMPLETE: "Enrollment Complete",
    PERSONAL_DETAILS_PENDING: "Personal Details Pending",
    PERSONAL_DETAILS_COMPLETE:"Personal Details Complete",
    IDV_PASS: "IDV Pass",
    IDV_PENDING: "IDV Pending",
    IDV_FAIL: "IDV Fail",
    DOCUMENTS_PENDING: "Documents Pending",
    DOCUMENTS_COMPLETE: "Documents Complete",//done
    INSURANCE_UPLOAD_COMPLETE: "Insurance Upload Complete",
    PAYMENT_AGREEMENT_PENDING: "Payment Agreement Pending",
    PAYMENT_AGREEMENT_COMPLETE: "Payment Agreement Complete",
    OCR_CLEAR: "OCR Clear",
    OCR_FAILED: "OCR Failed",
    OCR_MANUAL_CLEAR: "Manual Clear",
    INSURANCE_IMAGE_INVALID: "Insurance Image Invalid",
    MVR_SUBMITTED: "MVR Submitted",
    MVR_PENDING: "MVR Pending",
    MVR_SUSPENDED:"MVR Suspended",
    MVR_CLEAR: "MVR Clear",
    MVR_CONSIDER: "MVR Consider",
    MVR_DENIED: "MVR Denied",
    CBC_SUBMITTED: "CBC Submitted",
    CBC_PENDING: "CBC Pending",
    CBC_SUSPENDED:"CBC Suspended",
    CBC_CLEAR: "CBC Clear",
    CBC_CONSIDER: "CBC Consider",
    CBC_DENIED: "CBC Denied",
    APPLICATION_WAITLIST: "Application Waitlist",
    DMS_DATA_ISSUE: "DMS Data Issue",
    APPROVED_AUTO: "Approved Auto",
    APPROVED_MANUAL: "Approved Manual",
    WITHDRAWN : "Withdrawn"
}

export const Status = [
    {id:301, name: FunnelStatus.CREATED, label: "Created",stepName: FunnelSteps.ACCOUNT_CREATION },
    {id:302, name: FunnelStatus.ENROLLMENT_ON_HOLD, label: "Enrollment On-Hold",stepName:FunnelSteps.ACCOUNT_CREATION},
    {id:303, name: FunnelStatus.ENROLLMENT_COMPLETE, label: "Enrollment Complete",stepName:FunnelSteps.ACCOUNT_CREATION},
    {id:304, name: FunnelStatus.PERSONAL_DETAILS_PENDING, label: "Pending",stepName:FunnelSteps.PERSONAL_DETAILS},
    {id:305, name: FunnelStatus.PERSONAL_DETAILS_COMPLETE, label: "Complete",stepName:FunnelSteps.PERSONAL_DETAILS},
    {id:306, name: FunnelStatus.IDV_PASS, label: "IDV Pass",stepName:FunnelSteps.IDV},
    {id:307, name: FunnelStatus.IDV_PENDING, label: "IDV Pending",stepName:FunnelSteps.IDV},
    {id:308, name: FunnelStatus.IDV_FAIL, label: "IDV Fail",stepName:FunnelSteps.IDV},
    {id:309, name: FunnelStatus.DOCUMENTS_PENDING, label: "Pending",stepName:FunnelSteps.DOCUMENTS},
    {id:310, name: FunnelStatus.DOCUMENTS_COMPLETE, label: "Complete",stepName:FunnelSteps.DOCUMENTS},
    {id:311, name: FunnelStatus.INSURANCE_UPLOAD_COMPLETE, label: "Complete",stepName: FunnelSteps.INSURANCE_UPLOAD},
    {id:312, name: FunnelStatus.PAYMENT_AGREEMENT_PENDING, label: "Pending",stepName: FunnelSteps.PAYMENT_AGREEMENT},
    {id:313, name: FunnelStatus.PAYMENT_AGREEMENT_COMPLETE, label: "Complete",stepName: FunnelSteps.PAYMENT_AGREEMENT},
    {id:314, name: FunnelStatus.OCR_CLEAR, label: "Ocr Clear",stepName: FunnelSteps.INSURANCE_VERFICATION},
    {id:315, name: FunnelStatus.OCR_FAILED, label: "Ocr Failed",stepName: FunnelSteps.INSURANCE_VERFICATION},
    {id:316, name: FunnelStatus.OCR_MANUAL_CLEAR, label: "Manual Clear",stepName: FunnelSteps.INSURANCE_VERFICATION},
    {id:317, name: FunnelStatus.INSURANCE_IMAGE_INVALID, label: "Insurance Image Invalid",stepId:9,stepName: FunnelSteps.INSURANCE_VERFICATION},
    {id:318, name: FunnelStatus.MVR_SUBMITTED, label: "Submitted",stepName: FunnelSteps.MVR},
    {id:319, name: FunnelStatus.MVR_PENDING, label: "Pending",stepName: FunnelSteps.MVR},
    {id:320, name: FunnelStatus.MVR_SUSPENDED, label: "Mvr Suspended",stepName: FunnelSteps.MVR},
    {id:321, name: FunnelStatus.MVR_CLEAR, label: "Clear",stepName: FunnelSteps.MVR},
    {id:322, name: FunnelStatus.MVR_CONSIDER, label: "Consider",stepName: FunnelSteps.MVR},
    {id:323, name: FunnelStatus.MVR_DENIED, label: "Denied",stepName: FunnelSteps.MVR},
    {id:324, name: FunnelStatus.CBC_SUBMITTED, label: "Submitted",stepName: FunnelSteps.CBC},
    {id:325, name: FunnelStatus.CBC_PENDING, label: "Pending",stepName: FunnelSteps.CBC},
    {id:326, name: FunnelStatus.CBC_SUSPENDED, label: "Cbc Suspended",stepName: FunnelSteps.CBC},
    {id:327, name: FunnelStatus.CBC_CLEAR, label: "Clear",stepName: FunnelSteps.CBC},
    {id:328, name: FunnelStatus.CBC_CONSIDER, label: "Consider",stepName: FunnelSteps.CBC},
    {id:329, name: FunnelStatus.CBC_DENIED, label: "Denied",stepName: FunnelSteps.CBC},
    {id:330, name: FunnelStatus.APPLICATION_WAITLIST, label: "Waitlist",stepName: FunnelSteps.APPLICATION_COMPLETE},
    {id:331, name: FunnelStatus.DMS_DATA_ISSUE, label: "Dms Data Issue",stepName: FunnelSteps.APPLICATION_COMPLETE},
    {id:332, name: FunnelStatus.APPROVED_AUTO, label: "Auto",stepName: FunnelSteps.APPLICATION_APPROVED},
    {id:333, name: FunnelStatus.APPROVED_MANUAL, label: "Manual",stepName: FunnelSteps.APPLICATION_APPROVED},
    {id:334, name: FunnelStatus.WITHDRAWN, label: "Withdrawn",stepName: FunnelSteps.WITHDRAWN}

]