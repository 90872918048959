import { mutation } from "@/harlem/stores/application.store";

export const setApplicationId = mutation<string, any>(
  "setApplicationId",
  (state, payload) => {
    state.id = payload;
  }
);
export const setApplicationStatus = mutation<string, any>(
  "setApplicationStatus",
  (state, payload) => {
    state.status = payload;
  }
);
export const setApplicationStep = mutation<string, any>(
  "setApplicationStep",
  (state, payload) => {
    state.currentStep = payload;
  }
);
export const setCandidateStatus = mutation<string, any>(
  "setCandidateStatus",
  (state, payload) => {
    state.candidateStatus = payload;
  }
);
export const setCandidateStep = mutation<string, any>(
  "setCandidateStep",
  (state, payload) => {
    state.candidateStep = payload;
  }
);
