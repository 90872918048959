import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "button-bar list-unstyled" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (button) => {
      return (_openBlock(), _createElementBlock("li", {
        key: button.id,
        class: _normalizeClass(["button secondary", { active: _ctx.selectedButtonId === button.id }]),
        onClick: ($event: any) => (_ctx.select(button))
      }, _toDisplayString(button.label), 11, _hoisted_2))
    }), 128)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}