
import { defineComponent } from 'vue';

export default defineComponent({
  name: "IconWarningTriangle",
  props: {
    color: {
      required: false,
      default: ""
    },
    width: {
      required: false,
      default: ""
    },
    height: {

    }
  }
});
