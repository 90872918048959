<template>
  <div id="branch-message" class="modal" aria-hidden="true">
    <div class="modal-overlay" tabindex="-1" >
      <div
        class="modal-container custom-modal-container"
        role="dialog"
        aria-modal="true"
        aria-labelledby="aria-labelledby"
      >
        <header class="modal-header">
          <div class="close-modal close-modal-header" aria-label="Close modal" >
          </div>
        </header>
        <div class="modal-content">
          <slot>
            <h1>Get paid with Branch</h1>
            <br />
    
            <br />
            <p class="text-alignment">
                You are leaving from our application and will be redirected to branch application to claim your digital wallet
            </p>
            <br />
            <p class="text-alignment">
                Login link will be shared with you through SMS & EMAIL
            </p>
            <div class="button-group button-group-right space-top module full">
              <button @click="agreeContinue" :class="['button']">Accept</button>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { state } from "@/harlem/stores";
export default {
  name: "branchMessage",
    props: {
    url: {
      type: String,
      required: true,
    },
    },
  methods: {
    agreeContinue() {
      // window.location.href = this.$props.url
      window.open(this.$props.url)
      this.$router.push({ name: 'completed', query: { locationId: state.user.locationId }})
    },
  },
};
</script>