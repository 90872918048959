import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_arrow_left = _resolveComponent("icon-arrow-left")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_icon_arrow_left, {
      text: _ctx.text,
      goto: _ctx.goto,
      onArrowClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emit('arrow-clicked')))
    }, null, 8, ["text", "goto"])
  ]))
}