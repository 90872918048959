
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import WalmartPrivacyPolicy from "@/components/modals/WalmartPrivacyPolicy.vue";
import micromodal from "micromodal";
import router from "@/router";

export default defineComponent({
  name: "PortalFooter",
  components: { WalmartPrivacyPolicy },
  emits: ["menuopen"],
  setup() {
    const isModalOpen = ref(false)
    const openModal = (modal: string) => {
      isModalOpen.value = true
      micromodal.show("walmart-privacy-policy");
    };

    onMounted(() => {
      window.onpopstate = () => {
        closeModal()
      };

      router.beforeEach((to, from, next) => {
          if (isModalOpen.value) {
            next(false);
            closeModal()
          } else {
            next(true);
          }
        })
    })
    onUnmounted(() => {
      closeModal()
    })
    const closeModal = () => {
      isModalOpen.value = false
      micromodal.close("");
    }
    return {
      openModal,
      closeModal,
      isModalOpen
    }
  },
});
