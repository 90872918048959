import { mutation } from "@/harlem/stores/funnel.store";

export const setFunnelId = mutation<string, any>(
  "setFunnelId",
  (state, payload) => {
    state.id = payload;
  }
);

export const setFunnelSteps = mutation<any, any>(
  "setFunnelSteps",
  (state, payload) => {
    state.steps = payload;
  }
);
