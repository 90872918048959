import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "confirm-add-earning-method",
  class: "modal",
  "aria-hidden": "true"
}
const _hoisted_2 = {
  class: "modal-window_overlay policy",
  tabindex: "-1"
}
const _hoisted_3 = { class: "modal-window_container confirm-payment-container" }
const _hoisted_4 = { class: "confirm-payment_header" }
const _hoisted_5 = { class: "confirm-payment_body" }
const _hoisted_6 = { class: "button-group-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconAbout = _resolveComponent("IconAbout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createVNode(_component_IconAbout)
        ]),
        _createElementVNode("div", _hoisted_4, "Make " + _toDisplayString(_ctx.paymentType) + " your new primary earnings account?", 1),
        _createElementVNode("div", _hoisted_5, "Starting " + _toDisplayString(_ctx.payStartDate) + ", your earnings will be deposited in your " + _toDisplayString(_ctx.paymentType) + " account. Until then, continue using your current primary account. ", 1),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal())),
            class: "button-secondary"
          }, "Change Later"),
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirmPaymentSwitch())),
            class: "continue-button"
          }, "Confirm Account Change")
        ])
      ])
    ])
  ]))
}