
import { defineComponent } from "vue";
import { Field } from "vee-validate";
import { FieldError } from "@/components";
export default defineComponent({
  name: "AchAccountType",
  components: {
    Field,
    FieldError
  },
})
