
  import { defineComponent } from "vue";
  import { Field } from "vee-validate";
  import { FieldError } from "@/components";
  import { maska } from "maska";
  import "../../assets/css/components/formInput.scss"

  export default defineComponent({
    name: "TextFieldValidated",
    components: { 
      FieldError, 
      Field,
      },
    directives: { maska },
    data() {
      return {
        inputValue: "",
      };
    },
    props: {
      value: {
        type: String,
        required: false,
        default: "",
      },
      placeholder: {
        type: String,
        required: false,
      },
      floating : {
        type : Boolean,
        required:false,
        default: true,
      },
      id: {
        type: String,
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
      required: {
        type: Boolean,
        required: false,
        default: false,
      },
      validateOnMount: {
        type: Boolean,
        required: false,
        default: false,
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      type: {
        type: String,
        required: false,
        default: "text",
      },
      useCheck: {
        type: Boolean,
        required: false,
        default: false,
      },
      noOnChange: {
        type: Boolean,
        required: false,
        default: false,
      },
      noModelUpdate: {
        type: Boolean,
        required: false,
        default: false,
      },
      infoPopover: {
        type: Boolean,
        required: false,
        default: false,
      },
      infoPopoverContent: {
        type: Object,
        required: false,
        default: () => {
          return { title: "", body: "" };
        },
      },
      inputMask: {
        required: false,
      },
      displayWarning: {
        type: Boolean,
        required: false,
        default: false,
      },
      maxlength: {
        type: Number|| String,
        required: false,
        // default: false,
      },
    },
    watch: {
      value(newValue) {
        this.inputValue = newValue;
      },
    },
  });
