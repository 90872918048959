
import { defineComponent, onMounted } from "vue";
import { IconAbout } from "@/components"
import { state } from "@/harlem/stores";
import { gTagEvent } from "@/util/GoogleAnalytics";
export default defineComponent({
    name: "ConfirmMakePrimaryEarning",
    components: {
        IconAbout
    },
    emits: ['continue', 'exit'],
    props: {
        payStartDate: {
            required: false
        },
        paymentType: {
            required: false
        }
    },
    setup(props, {emit}) {
        const closeModal = () => {
            emit('exit')
        }
        const confirmPaymentSwitch = () => {
            emit('continue')
        }
        onMounted(() => {
            gTagEvent(`View Confirm Make Primary Switch`, "Active Accounts", `partner_id: ${state.user.partnerId}`)
        })

        return {closeModal, confirmPaymentSwitch}
    }
});
