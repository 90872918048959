<template>

 <div>
    <h2 class="middle"> Your session has expired </h2>

     
                    <div class="ferror middle" style="height: 200px; width : 150px ; margin-left:auto; margin-right:auto" >
                    <img class="ddi-branch-logo" src="@/assets/alert-octagon_l.png" alt="DDI and Branch"/>   </div>
 
          <h5 class="middle"><span>click <a href="`${config.VUE_APP_PARTNER_PORTAL_BASE_URL}/login`">here</a>  to login</span></h5>
          </div>
</template>

<script>

export default {
    name: 'SessionExpired',  

       setup() {
        // debugger
        localStorage.clear();
        sessionStorage.clear();
    
},
}
</script>

<style scoped>
.middle {
  text-align: center;
}


</style>
