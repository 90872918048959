import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "field-group" }
const _hoisted_2 = { class: "field" }
const _hoisted_3 = ["for"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_flat_pickr = _resolveComponent("flat-pickr")!
  const _component_Field = _resolveComponent("Field")!
  const _component_FieldError = _resolveComponent("FieldError")!
  const _directive_svg = _resolveDirective("svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", { for: _ctx.id }, [
        _createTextVNode(_toDisplayString(_ctx.label), 1),
        (_ctx.required)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, "*"))
          : _createCommentVNode("", true)
      ], 8, _hoisted_3),
      _createElementVNode("div", {
        class: "input-has-icon",
        id: `${_ctx.name}_input_container`
      }, [
        _createVNode(_component_Field, {
          id: _ctx.id,
          name: _ctx.name,
          validateOnMount: _ctx.validateOnMount,
          modelValue: _ctx.model,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model) = $event))
        }, {
          default: _withCtx(({ field, errors }) => [
            _createVNode(_component_flat_pickr, _mergeProps(field, {
              modelValue: field.value,
              "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
              config: _ctx.calendarConfig,
              class: { 'has-error': errors.length > 0 },
              "aria-autocomplete": false,
              "aria-placeholder": 'dd-mm-yyyy',
              placeholder: 'dd-mm-yyyy',
              id: `input-${_ctx.id}`,
              ref: "calendar"
            }), null, 16, ["modelValue", "onUpdate:modelValue", "config", "class", "id"]),
            _withDirectives((_openBlock(), _createElementBlock("svg", {
              size: "18 18",
              ref: "calendarIcon",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleCalendar && _ctx.toggleCalendar(...args)))
            }, null, 512)), [
              [_directive_svg, 'icon-calendar']
            ])
          ]),
          _: 1
        }, 8, ["id", "name", "validateOnMount", "modelValue"])
      ], 8, _hoisted_5),
      _createVNode(_component_FieldError, { name: _ctx.name }, null, 8, ["name"])
    ])
  ]))
}