
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  props: {
    cancelLabel: {
      type: String,
      required: false,
      default: "Cancel",
    },
    cancelRoute: {
      type: String,
      required: false,
      default: "",
    },
    cancelAction: {
      type: Function,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const router = useRouter();
    const cancel = () => {
      if (props.cancelAction) {
        props.cancelAction();
      } else if (props.cancelRoute) {
        router.push(props.cancelRoute);
      } else {
        router.back();
      }
    };

    return {
      cancel,
    };
  },
});
