
import { defineComponent, ref } from "vue";
import FieldError from "@/components/forms/FieldError.vue";
import TextFieldValidated from "@/components/forms/TextFieldValidated.vue";
import Dropdown from "primevue/dropdown";
import { Field, Form } from "vee-validate";
import { event } from "vue-gtag";
// import { IconWarningOctagon } from "@/components";

export default defineComponent({
  name: "SignupQuestion",
  components: {
    Field,
    FieldError,
    TextFieldValidated,
    Dropdown,
    // IconWarningOctagon,
  },
  emits: ["change"],
  props: {
    question: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    errors: {
      type: String,
      required: true,
      default: "",
    },
    isSubmitted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const otherDetails = ref(false);
    const vehicleTypeSelected = ref(false);
    const walmartStoreDetails = ref(false);
    const howDidYouHearLabel = ref(false);
    const checkedRadioNot18 = ref(false);
    const selectedMedia = ref();
    const mediaOptions = ref([
      {
        label: "Social Media",
        code: "SOCIAL",
        items: [
          { label: "Facebook", value: "facebook" },
          { label: "Tik Tok", value: "tiktok" },
          { label: "Internet Search", value: "internet" },
          { label: "YouTube", value: "youtube" },
          { label: "Email", value: "email" },
        ],
      },
      {
        label: "Job Ad",
        code: "JOBAD",
        items: [
          { label: "Indeed", value: "indeed" },
          { label: "Glass Door", value: "glassdoor" },
          { label: "Nextdoor", value: "nextdoor" },
        ],
      },
      {
        label: "Referral",
        code: "REF",
        items: [
          { label: "DoorDash Driver", value: "doordash" },
          { label: "Spark Now Driver", value: "spark" },
          { label: "Uber Driver", value: "uber" },
          { label: "In Store Recommendation", value: "store" },
          { label: "Friend or Family", value: "friend" },
        ],
      },
      {
        label: "App Store",
        code: "APP",
        items: [
          { label: "Google Play", value: "google" },
          { label: "Apple App Store", value: "apple" },
          { label: "Other", value: "other" },
        ],
      },
    ]);

    const vehicleTypeOptions = ref([
      { key: "compact", label: "Compact",value: false },
      { key: "sedan", label: "Sedan", value: false },
      { key: "suv" ,label: "SUV", value: false },
      { key: "van", label: "Van", value: false },
      { key: "pickup_truck",  label: "Pick-Up Truck", value: false },
    ]);
    const handleRadioChange = (name: string, value: string) => {
      if (name === "isOver18") {
        if (value === "YES") checkedRadioNot18.value = false;
        else checkedRadioNot18.value = true;
      }
      emit("change", { name, value });
    };

    const handelCheckboxChange = (key:any) => {
      emit('change',{name:key,value:vehicleTypeOptions.value})
    };
    const handleDropdownChange = (name: string, event: any) => {
      const value = event.value.value;
      howDidYouHearLabel.value = true;
      otherDetails.value = event.value.value === "other" ? true : false;
      walmartStoreDetails.value =
        event.value.value === "walmart-store" ? true : false;
      emit("change", { name, value });
    };
    const handleTextChange = (name: string, event: any) => {
      const value = event.target.value;
      emit("change", { name, value });
    };

    return { 
      props,
      otherDetails,
      walmartStoreDetails,
      howDidYouHearLabel,
      checkedRadioNot18,
      handleRadioChange,
      handleDropdownChange,
      mediaOptions,
      selectedMedia,
      handleTextChange,
      handelCheckboxChange,
      vehicleTypeSelected,
      vehicleTypeOptions
    };
  },
});
