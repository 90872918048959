import { readonly, provide, reactive } from "vue";
import { signupState, signupSetters } from "@/store/providers/signup.provider";
import {
  rootAppSetters,
  rootAppState,
} from "@/store/providers/root-app.provider";
import { userSetters, userState } from "@/store/providers/user.provider";
import { SignupSetters, SignupState } from "@/store/states/signup.state";
import { UserSetters, UserState } from "@/store/states/user.state";
import { RootAppSetters, RootAppState } from "@/store/states/root-app.state";

export const StoreStateSymbol = "newState";
export const StoreUpdateSymbol = "stateUpdate";

// export const StoreStateSymbol: InjectionKey<any> = Symbol(
//   "Store provider identifier"
// );
// export const StoreUpdateSymbol: InjectionKey<any> = Symbol(
//   "Store update provider identifier"
// );

export type stateUpdateInterface = {
  signup: SignupSetters;
  user: UserSetters;
  rootApp: RootAppSetters;
  restore: (arg0: stateInterface) => void;
};
export type stateInterface = {
  signup: SignupState;
  user: UserState;
  rootApp: RootAppState;
};
const newState = reactive({
  signup: signupState,
  user: userState,
  rootApp: rootAppState,
}) as unknown as stateInterface;

const update = {
  signup: { ...signupSetters },
  user: { ...userSetters },
  rootApp: { ...rootAppSetters },
  restore: (val) => {
    newState.signup = val.signup;
    newState.user = val.user;
    newState.rootApp = val.rootApp;
  },
} as stateUpdateInterface;

// watch(
//   newState,
//   (val) => {
//     const globalState = localStorage.getItem("globalState");
//     if (globalState !== JSON.stringify(val)) {
//       localStorage.setItem("globalState", JSON.stringify(val));
//     }
//   },
//   { deep: true }
// );

export default (): void => {
  provide(StoreStateSymbol, readonly(newState));
  provide(StoreUpdateSymbol, update);
};
