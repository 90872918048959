import { restApi } from "@/http/http.config";


class CommonService {
triggerRecruticsScript= async()=>{
    restApi.get("/api/getRecruiticsScript").then((data) => {
        const scriptVal = data?.data?.result[0]?.value;
        const recruitcsScript = document.createElement("script");
        recruitcsScript.setAttribute("src", scriptVal);
        document.head.appendChild(recruitcsScript);
      });
    }

triggerJobCaseScript= async()=>{   
      restApi.get("/api/getJobcasePixelScript").then((data) => {
        const resultVal = data?.data?.result[0]?.value;
        const jcPixelScript = document.createElement("img");
        jcPixelScript.setAttribute("src", resultVal);
        jcPixelScript.setAttribute('width', '0');
        jcPixelScript.setAttribute('height', '0');
        document.body.appendChild(jcPixelScript);
      });
    }
}

export default new CommonService();
