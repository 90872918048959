import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: { width: '100%', display: 'flex', justifyContent: 'center' } }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    id: "modal-video",
    onClose: _ctx.closeIFrame
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.showIframe)
          ? (_openBlock(), _createElementBlock("iframe", {
              key: 0,
              ref: "videoFrame",
              id: "videoFrame",
              allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
              allowfullscreen: "true",
              height: "315",
              src: _ctx.videoSrc,
              style: {"border":"0"},
              width: "560",
              title: "Learn More"
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["onClose"]))
}