import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "button-group" }
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "additional-buttons"),
    _createElementVNode("button", {
      class: "button",
      disabled: !_ctx.saveEnabled,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
    }, _toDisplayString(_ctx.saveLabel), 9, _hoisted_2),
    _createElementVNode("a", {
      class: "button secondary",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
    }, _toDisplayString(_ctx.cancelLabel), 1)
  ]))
}