
import micromodal from "micromodal";
import { defineComponent, ref } from "vue";
import { Field } from "vee-validate";
import { FieldError } from "@/components";
import AchNumberText from "@/modules/signup/directDeposit/AchNumberText.vue";
export default defineComponent({
  name: "AchBankNumber",
  components: {
    Field,
    FieldError,
    AchNumberText
  },
  props: ['type', 'capitalType', 'maxlength', 'errors'],
  setup(props) {
    const maskedBankNumber = ref('')
    const originalBankNumber = ref('')
    const confirmNumber = 'confirm' + props.capitalType
    function maskBankNumber(event) {
      const value = event.target.value
      originalBankNumber.value = value
      if (value.length > 4) {
        maskedBankNumber.value = '*'.repeat(value.length-4) + value.slice(value.length-4)
        event.target.value = maskedBankNumber.value
      }
    }
    function showMaskedBankNumber(event) {
      event.target.value = originalBankNumber.value
    }
    function resetNumbers() {
      maskedBankNumber.value = ''
      originalBankNumber.value = ''
    }
    return {
      micromodal,
      confirmNumber,
      maskedBankNumber,
      originalBankNumber,
      maskBankNumber,
      showMaskedBankNumber,
      resetNumbers
    }
  }
})
