import { createStore } from "@harlem/core";

const STATE = {
  date: "",
  partner: {
    name: "",
    firstName: "",
    lastName: "",
    contractDate: "",
    dateOfBirth: "",
    phone: "",
    email: "",
    ssn: "",
    signature: "",
    initials: "",
    address: {
      street1: "",
      street2: "",
      street3: "",
      city: "",
      state: "",
      zip: "",
    },
    license: {
      license_state: "",
      license_number: "",
      license_expiry: "",
    },
    vehicle: {
      make: "",
      model: "",
      year: "",
    },
  },
  ddi: {
    corporate_address: "",
    address: {
      street1: "",
      street2: "",
      street3: "",
      city: "",
      state: "",
      zip: "",
    },
    name: "",
    phone: "",
    onboardingEmail: "",
  },
};

const restoreState = (): any => {
  const mergeTagsState = sessionStorage.getItem("ddi:mergeTags");
  if (!mergeTagsState) {
    return STATE;
  } else {
    return {
      ...JSON.parse(mergeTagsState),
    };
  }
};

export const { state, getter, mutation, on } = createStore(
  "mergeTags",
  restoreState()
);
