import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "file-upload",
  method: "post",
  enctype: "multipart/form-data"
}
const _hoisted_2 = { class: "file-upload" }
const _hoisted_3 = ["accept"]
const _hoisted_4 = { class: "button secondary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        type: "file",
        accept: _ctx.accepts,
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.uploadFile && _ctx.uploadFile(...args)))
      }, null, 40, _hoisted_3),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.label), 1)
    ])
  ]))
}