
import { defineComponent } from "vue";
import { state } from "@/harlem/stores";
import string from "@/modules/signup/directDeposit/string";
export default defineComponent({
  name: "DirectDepositHeader",
  setup() {
    const locationName = state.user.locationName
    return {
      string,
      locationName
    }
  }
})
