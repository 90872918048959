<template>
  <div class="icon icon-right">
    <svg v-svg="'icon-arrow-right'" size="18 18" />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "IconArrowRight",
  emits: ["clicked"],
});
</script>
