
import { defineComponent } from "vue";
import { Field } from "vee-validate";
import { FieldError } from "@/components";

export default defineComponent({
  name: "StateSelectorValidated",
  components: { Field, FieldError },
  data() {
    return {
      selectedState: "",
    };
  },
  props: {
    id: {
      type: String,
      default: "state",
    },
    value: {
      type: String,
      required: false,
      default: "",
    },
    name: {
      type: String,
      default: "state",
    },
    label: {
      type: String,
      required: false,
      default: "State Issued*",
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    validateOnMount: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasError: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    driverLicense: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  watch: {
    value(newValue) {
      this.selectedState = newValue;
    },
  },
  methods: {
    onChangeState(event) {
      this.selectedState = event;
    },
  },
});
