import { markRaw } from "vue";
import { IconOne, IconBank, IconBranch } from "@/components";

export const activeAccountsData = [
  {
    id: 1,
    logo: markRaw(IconOne),
    type: 'one',
    title: 'ONE Account',
    text: 'Deposited right after every trip',
    isPrimary: false,
    isPending: false
  },
  {
    id: 2,
    logo: markRaw(IconBank),
    type: 'ach',
    title: 'Direct Deposit',
    text: 'Deposited weekly',
    accountNumber: '',
    isSavings: false,
    isPrimary: false,
    isPending: false
  },
  {
    id: 3,
    logo: markRaw(IconBranch),
    type: 'branch',
    title: 'Branch Wallet',
    text: 'Deposited weekly',
    isPrimary: false,
    isPending: false
  },
]