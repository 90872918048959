
import { useRouter } from "vue-router";
import { IconArrowLeft } from "@/components";
import { disableEarningsTab } from "@/router/Helpers";
import { defineComponent, ref, onBeforeMount } from "vue";
import Snackbar from "@/components/snackbar/Snackbar.vue";
import checklistMethods from "../signup/components/Checklist/Helpers/Index";
import ActiveAccounts from '@/modules/earnings/activeAccounts/ActiveAccounts.vue'
import EarningsAccount from "@/modules/signup/earningsAccount/EarningsAccount.vue";

export default defineComponent({
  name: "ManageEarnings",
  components: { 
    Snackbar,
    IconArrowLeft,
    ActiveAccounts,
    EarningsAccount
  },
  setup() {
    const isLoading = ref(true);
    const showPaymentSetUp = ref(false)
    const currentPayment = ref('')
    const router = useRouter()
    onBeforeMount(async () => {
      await disableEarnings()
      isLoading.value = false
    })

    const disableEarnings = async () => {
      await disableEarningsTab().then((data) => {
        const isDisabled = data;
        if (isDisabled) {
          router.push({ name: "dashboard" })
        }
      })
    }

    return {
      isLoading,
      currentPayment,
      showPaymentSetUp
    };
  },
  methods: {
    isMobile() {
      return checklistMethods.isMobile();
    },
  },
});
