import { mutation } from "@/harlem/stores/signup.store";

export const setDeliveryMap = mutation<string, any>(
  "setDeliveryMap",
  (state, payload) => {
    state.deliveryMap = payload;
  }
);
export const setClientId = mutation<string, any>(
  "setClientId",
  (state, payload) => {
    state.clientId = payload;
  }
);
export const setLocationId = mutation<string, any>(
  "setLocationId",
  (state, payload) => {
    state.locationId = payload;
  }
);

export const setLocationName = mutation<string, any>(
  "setLocationName",
  (state, payload) => {
    state.locationName = payload;
  }
);

export const setLocationLogo = mutation<string, any>(
  "setLocationLogo",
  (state, payload) => {
    state.locationLogo = payload;
  }
);
export const setOnboardingSpecialist = mutation<string, any>(
  "setOnboardingSpecialist",
  (state, payload) => {
    state.onBoardingSpecialist = payload;
  }
);

export const setPersonalDetails = mutation<string, any>(
  "setPersonalDetails",
  (state, payload) => {
    state.personalDetails = payload;
  }
);

export const setBusinessDetails = mutation<string, any>(
  "setBusinessDetails",
  (state, payload) => {
    state.businessDetails = payload;
  }
);

export const setIRSForm = mutation<string, any>(
  "setIRSForm",
  (state, payload) => {
    state.IRSForm = payload;
  }
);

export const setAlertTimes = mutation<any, any>(
  "setAlertTimes",
  (state, payload) => {
    state.alertTimes = payload;
  }
);
