<template>
    <div id="ddi-privacy-policy" class="modal" aria-hidden="true">
    <div
      class="modal-window_overlay policy"
      tabindex="-1"
    >
      <div
        class="modal-window_container"
        role="dialog"
        aria-modal="true"
        aria-labelledby="aria-labelledby"
      >
        <header class="modal-header">
          <div
            class="close-modal close-modal-header"
            aria-label="Close modal"
          >
          </div>
        </header>
        <div class="modal-content">
          <slot>
               <div class="e1289-8 x-text">
      <p>I. ACKNOWLEDGEMENT AND ACCEPTANCE OF TERMS</p>
      <p>
        Delivery Drivers, Inc is committed to protecting your privacy. This
        Privacy Statement sets forth our current privacy practices with regard
        to the information we collect when you or your computer interact with
        our website. By accessing www.ddiwork.com, you acknowledge and fully
        understand Delivery Drivers, Inc. Privacy Statement and freely consent
        to the information collection and use practices described in this
        Website Privacy Statement.
      </p>
      <p>II. PARTICIPATING MERCHANT POLICIES</p>
      <p>
        Related services and offerings with links from this website, including
        vendor sites, have their own privacy statements that can be viewed by
        clicking on the corresponding links within each respective website.
        Online merchants and others who participate in Delivery Drivers, Inc.
        services are encouraged to participate in industry privacy initiatives
        and to take a responsible attitude towards consumer privacy. However,
        since we do not have direct control over the policies or practices of
        participating merchants and other third parties, we are not responsible
        for the privacy practices or contents of those sites. We recommend and
        encourage that you always review the privacy policies of merchants and
        other third parties before you provide any personal information or
        complete any transaction with such parties.
      </p>
      <p>III. INFORMATION WE COLLECT AND HOW WE USE IT</p>
      <p>
        Deliver Drivers, Inc. collects certain information from and about its
        users three ways: directly from our Web Server logs, the user, and with
        Cookies.
      </p>
      <ol>
        <li>WEB SERVER LOGS</li>
      </ol>
      <p>
        When you visit our Website, we may track information to administer the
        site and analyze its usage. Examples of information we may track
        include:
      </p>
      <ul>
        <li>Your Internet protocol address</li>
        <li>The kind of browser or computer you use</li>
        <li>Number of links you click within the site</li>
        <li>State or country from which you accessed the site</li>
        <li>Date and time of your visit</li>
        <li>Name of your Internet service provider</li>
        <li>Web page you linked to our site from</li>
        <li>Pages you viewed on the site</li>
      </ul>
      <p>2. USE OF COOKIES</p>
      <p>
        Delivery Drivers, Inc. may use cookies to personalize or enhance your
        user experience. A cookie is a small text file that is placed on your
        hard disk by a Web page server. Cookies cannot be used to run programs
        or deliver viruses to your computer. Cookies are uniquely assigned to
        you, and can only be read by a Web Server in the domain that issued the
        cookie to you. One of the primary purposes of cookies is to provide a
        convenience feature to save you time. For example, if you personalize a
        web page, or navigate within a site, a cookie helps the site to recall
        your specific information on subsequent visits. Hence, this simplifies
        the process of delivering relevant content and eases site navigation by
        providing and saving your preferences and login information as well as
        providing personalized functionality.
      </p>
      <p>
        Delivery Drivers, Inc. reserves the right to share aggregated site
        statistics with partner companies, but does not allow other companies to
        place cookies on our website unless there is a temporary, overriding
        customer value (such as merging into Delivery Drivers, Inc.’s website
        that relies on third-party cookies).
      </p>
      <p>
        You have the ability to accept or decline cookies. Most Web browsers
        automatically accept cookies, but you can usually modify your browser
        setting to decline cookies. If you reject cookies by changing your
        browser settings then be aware that this may disable some of the
        functionality on our Website.
      </p>
      <p>3. PERSONAL INFORMATION USERS</p>
      <p>
        When you register, we will request some personal information such as
        name, address, email, telephone number or account number and other
        relevant information. Any financial information we collect is used only
        for providing you with our accounting&nbsp;services. For other types of
        registrations, we will ask for the relevant information. You may also be
        asked to disclose personal information to us so that we can provide
        assistance and information to you. For example, such data may be
        warranted in order to provide online technical support and
        troubleshooting.
      </p>
      <p>
        We will not disclose personally identifiable information we collect from
        you to third parties without your permission except to the extent
        necessary including:
      </p>
      <ul>
        <li>To fulfil your requests for services.</li>
        <li>To protect ourselves from liability,</li>
        <li>To respond to legal process or comply with law, or</li>
        <li>
          In connection with a merger, acquisition, or liquidation of the
          company.
        </li>
      </ul>
      <p>IV. USE OF WEB BEACONS OR GIF FILES</p>
      <p>
        Delivery Drivers, Inc. Web pages may contain electronic images known as
        Web beacons – sometimes also called single-pixel gifs that allow
        Delivery Drivers, Inc. to count users who have visited those pages and
        to deliver co-branded services. Delivery Drivers, Inc. may include Web
        beacons in promotional e-mail messages or newsletters in order to
        determine whether messages have been opened and acted upon.
      </p>
      <p>
        Some of these Web beacons may be placed by third-party service providers
        to help determine the effectiveness of our advertising campaigns or
        e-mail communications. These Web beacons may be used by these service
        providers to place a persistent cookie on your computer. This allows the
        service provider to recognize your computer each time you visit certain
        pages or emails and compile anonymous information in relation to those
        page views, which in turn enables us and our service providers to learn
        which advertisements and emails bring you to our website and how you use
        the site. Delivery Drivers, Inc. prohibits Web beacons from being used
        to collect or access your personal information.
      </p>
      <p>V. ACCESSING WEB ACCOUNT INFORMATION</p>
      <p>
        We will provide you with the means to ensure that personally
        identifiable information in your web account file is correct and
        current. You may review this information by contacting us or by logging
        into your person profile.
      </p>
      <p>VI. CHANGES TO THIS STATEMENT</p>
      <p>
        Delivery Drivers, Inc. has the discretion to occasionally update this
        privacy statement. When we do, we will also revise the ”updated” date at
        the top of this Privacy page. We encourage you to periodically review
        this privacy statement to stay informed about how we are helping to
        protect the personal information we collect. Your continued use of the
        service constitutes your agreement to this privacy statement and any
        updates.
      </p>
      <p>VII. CONTACTING US</p>
      <p>
        If you have questions regarding our Privacy Statement, its
        implementation, failure to adhere to this Privacy Statement and/or our
        general practices, please contact us at operations@ddiwork.com or send
        your comments to:
      </p>
      <p>
        Delivery Drivers, Inc.<br />
        Attention: Privacy Statement Personnel<br />
        2 Venture, Suite 430<br />
        Irvine, CA 92618
      </p>
      <p>
        Delivery Drivers, Inc. will use commercially reasonable efforts to
        promptly respond and resolve any problem or question.
      </p>
    </div>
    <div class="button-group button-group-right space-top module full">
    <button @click="agreeContinue" :class="['button']">
      Accept
    </button>
  </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "../../assets/css/components/modals.scss"
export default {
  name: "DdiPrivacyPolicy",
    emits: ["accept"],
  methods: {
    agreeContinue() {
      this.$emit("accept");
    },
  },
};
</script>

<style scoped></style>
