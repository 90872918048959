
import { defineComponent } from "vue";
import IconWarningTriangle from "@/components/icons/IconWarningTriangle.vue";
import { gTagEvent } from "@/util/GoogleAnalytics";
import { state } from "@/harlem/stores";
export default defineComponent({
    name: "ACHLocationDisabled",
    components: { IconWarningTriangle },
    mounted() {
        gTagEvent("View Location Disabled for Direct Deposit Screen", "Direct Deposit", `partner_id: ${state.user.partnerId}`)
    }
})
