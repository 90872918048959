import {
  FunnelQuestion,
  OnboardingStep,
  SignupSetters,
  SignupState,
} from "@/store/states/signup.state";
import { ref } from "vue";
const defaultState = {
  client: { id: "" },
  funnel: {
    id: "",
    steps: [
      {
        id: "",
        step: "",
        index: 0,
        onboardingStep: { stage: "", description: "" },
        questions: [
          {
            id: "",
            index: null,
            infoText: "",
            key: "",
            qualifier: false,
            questionText: "",
            required: false,
            step: "",
            type: "",
          },
        ],
      },
    ],
  },
  application: { id: "" },
  candidateProgress: { status: "", step: "" },
  partnerApplication: { id: "", status: "", currentStep: "" },
  location: { id: "", logo: ref(""), map: "", locationPartnerId: "" },
  alertTimes: [],
};
export const signupState = { ...defaultState } as SignupState;

export const signupSetters = {
  setClientId: (clientId) => {
    signupState.client.id = clientId;
  },
  setFunnelId: (funnelId) => {
    signupState.funnel.id = funnelId;
  },
  setLocationId: (locationId) => {
    signupState.location.id = locationId;
  },
  setLocationLogo: (logo) => {
    if (logo != null) {
      signupState.location.logo = logo;
    }
  },
  setLocationMap: (map) => {
    if (map != null) {
      signupState.location.map = map;
    }
  },
  setLocationPartnerId: (locationPartnerId) => {
    if (locationPartnerId != null) {
      signupState.location.locationPartnerId = locationPartnerId;
    }
  },
  setApplicationId: (applicationId) => {
    signupState.application.id = applicationId;
  },
  setCandidateProgress: (obj) => {
    signupState.candidateProgress = obj;
  },
  setPartnerApplication: (obj) => {
    signupState.partnerApplication = obj;
  },
  setFunnelSteps: (arr) => {
    signupState.funnel.steps = arr;
  },
  setAlertTimes: (arr) => {
    signupState.alertTimes = arr;
  },
} as SignupSetters;
