
import "../../assets/css/components/modals.scss"
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "TaxConsent",
  emits: ['open', 'submit'],
  setup() {
    const radioDisabled = ref(true)
    const choice = ref('')

    return {
      radioDisabled,
      choice,
    }
  },
  methods: {
    handleClick() {
      this.radioDisabled = false
      this.$emit('open')
    },
    submit() {
      this.$emit('submit', this.choice)
    }
  },
});
