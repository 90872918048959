<template>
  <div id="walmart-tax-consent-policy" class="modal" aria-hidden="true">
    <div class="modal-window_overlay policy" tabindex="-1">
      <div class="modal-window_container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby">
        <header class="modal-header">
          <div class="close-modal close-modal-header" aria-label="Close modal">
          </div>
        </header>
        <div class="modal-content">
          <slot>
            <b>
              <p class="c87"><span class="c55 headerTitle">Tax Documents Electronic<br />
                  Delivery & Disclosures</span></p>
            </b>
            <br />

            <p class="c45">
              <span class="c1 c3">Publication 1179 of the Internal Revenue Service requires a Payer to receive
                affirmative consent from recipients in
                order to deliver all tax documents electronically. This correspondence will provide you with disclosures
                required
                under IRS requirements. Read the disclosures below and you can choose to have your tax documents
                delivered electronically.</span>
              <br />
            </p>
            <b>
              <p class="c85"><span class="c43 headerTitle">IMPORTANT DISCLOSURE INFORMATION </span></p>
            </b>
            <br />
            <div class="consentP">
              <div>
                <span>1. </span>
                <p class="c45">

                  If you do not consent to electronic delivery, you will receive all tax documents in the mail, which
                  will be delivered to the address that we currently have on file, and you will not have access to any
                  of the tax documents online via the Spark Driver Profile.
                </p>
              </div>
              <div>
                <span>2. </span>
                <p class="c45">

                  Your consent to electronic delivery will apply to all future tax documents unless consent is
                  withdrawn by you.
                </p>
              </div>
              <div>
                <span>3. </span>
                <p class="c45">

                  If for any reason you would like a paper copy of your tax documents after you have consented to
                  electronic delivery,
                  you may log in to your
                  <a :href="domainURL" target="_blank"><u> Spark Driver Profile</u></a>, and change your preference
                  before the effective date of the
                  withdrawal of consent.
                </p>
              </div>
              <div>
                <span>4. </span>
                <p class="c45">


                  The Payer will cease providing tax documents to you electronically if you choose to withdraw the
                  consent if you
                  are no longer a Recipient or if regulations change to prohibit the form of delivery.
                </p>
              </div>
              <div>
                <span>5. </span>
                <p class="c45">

                  If you need to update your contact information that we have on file, use the settings within the
                  Spark Driver
                  Profile -> Settings -> Profile.
                </p>
              </div>
              <div>
                <span>6. </span>
                <p class="c45">
                  We will notify you if there are any changes to the contact information of the Payer.
                </p>
              </div>
              <div>
                <span>7. </span>
                <p class="c45">

                  You will need a computer, printer, and Adobe Acrobat software to access, print and retain your tax
                  documents.
                </p>
              </div>
              <div>
                <span>8. </span>
                <p class="c45">

                  Your tax documents may be required to be downloaded, printed, and attached to a federal, state, or
                  local
                  income tax return.


                </p>
              </div>
            </div>
            <br/>
           <div class="grid-middle flex">
                <button class="button-small" @click="taxConsent(true)">
                  Yes
                </button>

                <button class="button-small" @click="taxConsent(false)">
                  No
                </button>
              </div>
              <!-- </div> -->
             <!-- <div class="grid-middle">
                <button class="button" @click="handleConfirmBtn">
                  Got it
                </button>
              </div>
              <div>
                <consent @consent_details="ConsentDetails" />
              </div> -->
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "../../assets/css/components/modals.scss";
// import Consent from "../Consent.vue"
import micromodal from "micromodal";

export default {
  name: "WalmartTaxConsentPolicy",
  props: {
    includeConfirmBtn: { type: Boolean },
    handleConfirmBtn: { type: Function }
  },
  components: {
    // Consent,
  },
  emits: ["tax_consent_details"],
  data() {
    return {
      domainURL:"",
      taxConsentRead: false

    }},

  mounted() {
    this.domainURL=`${location.origin}/login` 

  },
  methods: {

    taxConsent(value){
      this.$emit("tax_consent_details", value);

    },
    ConsentDetails(data) {
      this.$emit("tax_consent_details", data);
    },
  },
};
</script>

<style scoped>
.spacepoints {
  margin-left: 108.7pt;
}

.points {
  margin-left: 0.25in
}

.headpoint {
  margin-bottom: 6.0pt;
  margin-left: .5in
}

.c45 {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.c87 {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}

.c85 {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}
.consentP div{
  display: inline-flex;
  padding-bottom: 2%;
}
.consentP span{
  padding: 4px 4px 0px 0px;
  font-size: 18px;
}
div, span, p{
  color: #6A7183;
}
.headerTitle{
  color:  #3B4459 !important;
}

.grid-middle{
box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    margin: 0px 15.5rem;
    justify-content: space-evenly;
}

@media screen and (min-width: 320px) and (max-width: 760px) {
  .grid-middle{
    margin: 0px;
  }
}
</style>
