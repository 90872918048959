import { proxyApi } from "@/http/http.config";

export const noActiveEarningAccounts = async () => {
  let res = false;
  await proxyApi.get('/getActiveEarningAccounts').then(({data}) => {
    const message = data.message
    if (message == "No active accounts"){
        return res = true
      }
  })
  return res
}

export const multiplePayDisabled = async () => {
  let isMultiplePayDisabled = false
  await proxyApi.get('/getLocationMultiplePay').then((data) => {
    if (data.data.result[0].is_multiple_pay_enabled !== true) {
      isMultiplePayDisabled = true
    }
  })
  return isMultiplePayDisabled
}
