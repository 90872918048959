import { getActiveEarningAccounts, getBranchStatus, getOneStatus, checkACHReachedMaxAttempts, defaultLocationPaymentsFailed } from "@/modules/funnelStatus/Helpers";
export const disableEarningsTab = async () => {
    const payeeID = sessionStorage.getItem('payeeID')
    const branchStatus:any = await getBranchStatus()
    const hasACHReachedMaxAttempts = await checkACHReachedMaxAttempts()
    const oneStatus = await getOneStatus()

    const defaultLocationAllPaymentsFailed = await defaultLocationPaymentsFailed();
    const noActiveAccounts = await getActiveEarningAccounts()
    const allEarningsFailed = oneStatus == 'DENIED' && branchStatus == "FAILED" && (hasACHReachedMaxAttempts)

    const isBranchPending = ['CREATED', 'REVIEW'].includes(branchStatus)
    const isONEPending = ['SUBMITTED', 'PENDING'].includes(oneStatus)
    const disableEarningsTab = (
        (isBranchPending || 
        ((isONEPending) && noActiveAccounts) || 
        (defaultLocationAllPaymentsFailed) ||
        allEarningsFailed) &&
        payeeID == 'null'
    )

    return disableEarningsTab;
}